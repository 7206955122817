import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../components/auth/store/userSlice'
import tableReducer from '../components/table/store/tableSlice'

const store = configureStore({
  reducer: {
    user: userReducer, // used for auth
    table: tableReducer, // used in DASHBOARD
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 250 },
    serializableCheck: { warnAfter: 250 },
  })
})

export default store
