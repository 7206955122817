import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../utils/utils'

const InfoBox = ({
  title = 'Title',
  content = 'Content',
  contentClassName = '',
}) => {
  return (
    <div
      className={classNames(
        'inline-flex items-center leading-none p-2 text-teal text-sm mx-1 select-none',
      )}
    >
      <span className="whitespace-nowrap">
        <span
          className={classNames(
            'inline-flex text-black font-bold h-6 px-3 justify-center items-center font-light',
          )}
        >
          {title}
        </span>
        <span className={classNames(
            'inline-flex px-2 font-normal',
            contentClassName,
          )}
        >
          {content}
        </span>
      </span>
    </div>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  contentClassName: PropTypes.string,
}

export default InfoBox
