import React from 'react'

const Svg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      clipRule="evenodd"
      viewBox="0 0 14 16"
      {...props}
    >
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M1.708 15.633V.367h7.008l3.586 3.583v11.683H1.708z"
      />
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M12.302 15.633H1.708V.367h7.008l.083.083v3.42h3.423l.08.08v11.683z"
      />
      <path
        fill="#888"
        fillRule="nonzero"
        d="M1.341 0v16h11.328V3.798L8.868 0H1.341zm.734 15.265V.734h6.357v3.503h3.503v11.028h-9.86z"
      />
      <path
        fill="#ed1c24"
        fillRule="nonzero"
        d="M12.669 5.564v1.274H1.341V5.564L.067 6.838v5.782h13.875V6.838l-1.273-1.274z"
      />
      <path fill="none" d="M1.722 7.954h10.684v4.031H1.722z" />
      <path
        d="M2.145 11.733V7.915h1.398c.207 0 .405.021.593.065.188.039.353.109.493.211.141.098.253.229.335.394.086.16.13.36.13.599 0 .235-.044.436-.13.605a1.174 1.174 0 01-.335.417 1.52 1.52 0 01-.487.241 2.146 2.146 0 01-.576.076h-.411v1.21h-1.01zm1.01-2.009h.364c.2 0 .347-.047.441-.141.098-.094.147-.227.147-.399 0-.173-.053-.294-.159-.364-.106-.071-.256-.106-.452-.106h-.341v1.01zm2.561 2.009V7.915h1.128c.29 0 .554.035.793.106.243.07.45.182.623.334.172.153.305.349.399.588.098.239.147.527.147.863 0 .337-.047.627-.141.87a1.552 1.552 0 01-1.005.945 2.438 2.438 0 01-.757.112H5.716zm1.01-.811h.059c.137 0 .267-.015.388-.047a.743.743 0 00.311-.176.822.822 0 00.212-.34c.051-.145.076-.329.076-.553 0-.223-.025-.405-.076-.546a.773.773 0 00-.212-.329.673.673 0 00-.311-.158 1.517 1.517 0 00-.388-.047h-.059v2.196zm2.761.811V7.915h2.491v.846h-1.48v.728h1.268v.846h-1.268v1.398H9.487z"
        fill="#fff"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default Svg
