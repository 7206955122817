import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../utils/utils'

const Button = ({ children, className, ...rest }) => {
  return (
    <button
      type="button"
      className={
        classNames(
          "disabled:opacity-30 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white hover:bg-gray-50 active:bg-gray-200",
          className
        )}
      {...rest}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

const PageButton = ({ children, className, ...rest }) => {
  return (
    <button
      type="button"
      className={
        classNames(
          "disabled:cursor-not-allowed disabled:opacity-30 relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 active:bg-gray-200",
          className
        )}
      {...rest}
    >
      {children}
    </button>
  )
}

PageButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export {
  Button,
  PageButton,
}
