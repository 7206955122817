import React from 'react'
import PropTypes from 'prop-types'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table'
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RefreshIcon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import { fn, classNames, /*dateTimeFormat,*/ toISOString, } from '../../utils/utils'
import {
  setPageSize as setPageSizeRedux,
  setPageIndex as setPageIndexRedux,
  setFilter as setFilterRedux,
  setSearch,
} from './store/tableSlice'
import { Button, PageButton } from './Button'
import { SortIcon, SortUpIcon, SortDownIcon } from './Icons'
import ReactTooltip from 'react-tooltip'

const getStatus = (status) => {
  const statusToLowerCase = status ? status.toLowerCase() : 'unknown'
  switch (statusToLowerCase) {
    case 'completed':
      return {cssClassNames: 'bg-green-100 text-green-800 font-bold', textToDisplay: 'Complete'}
    case 'processing':
      return {cssClassNames: 'bg-gray-300 text-gray-800 font-bold', textToDisplay: 'In processing'}
    case 'editable':
      return {cssClassNames: 'bg-yellow-300 text-yellow-800 font-bold', textToDisplay: 'Ready to open'}
    case 'error':
      return {cssClassNames: 'bg-red-100 text-red-800 font-bold', textToDisplay: 'Error'}
    default:
      return {cssClassNames: null, textToDisplay: statusToLowerCase}
  }
}

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const dispatch = useDispatch()
  const {
    search: searchValue,
  } = useSelector(state => state.table)
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(searchValue)
  const onChange = useAsyncDebounce(value => {
    // setGlobalFilter(value || undefined)
    dispatch(setSearch(value))
  }, 100)

  React.useEffect(() => {
    setGlobalFilter(value)
  }, [value, setGlobalFilter])

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">Search </span>
      <input
        type="text"
        className={classNames(
          'rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50',
          value ? 'bg-green-500 text-white' : '',
        )}
        value={value || ""}
        onChange={e => {
          const value = e.target.value
          setValue(value)
          onChange(value)
          // dispatch(setSearch(value))
        }}
        placeholder={`${count} records...`}
        data-testid="table.input.search"
      />
    </label>
  )
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array,
  globalFilter: PropTypes.array,
  setGlobalFilter: PropTypes.func,
}

// Custom filter UI for selecting a unique option from a list
export const SelectColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    render,
  },
}) => {
  const dispatch = useDispatch()
  const {
    filter: filterRedux,
  } = useSelector(state => state.table)
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // console.log(`### filterValue: ${filterValue} | ${filterRedux}`)
  // React.useEffect(() => {
  //   if (filterRedux && filterRedux.value) {
  //     setFilter(filterRedux)
  //   }
  // }, [filterRedux, setFilter])
  // console.log("#### FILTER:")
  // console.log(filterRedux)

  const filterValueToShow = filterRedux || filterValue
  // Render a multi-select box
  return (
    <>
      <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700">{render('Header')}</span>
        <select
          className={classNames(
            'rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50',
            // filterValue ? 'bg-green-500 text-white' : '',
            // filterValueToShow ? 'bg-green-500 text-white' : '',
            getStatus(filterValueToShow).cssClassNames,
          )}
          name={id}
          id={id}
          // value={filterValue}
          value={filterValueToShow}
          onChange={e => {
            // setFilter(e.target.value || undefined)
            // console.log("ONCHANGE")
            // console.log(e.target.value)
            setFilter(e.target.value)
            dispatch(setFilterRedux(e.target.value))
          }}
          data-testid="table.select.filter.status"
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {getStatus(option).textToDisplay}
            </option>
          ))}
        </select>
        {/* <Button className="flex" onClick={() => dispatch(resetFilterRedux())}>Reset</Button> */}
      </label>

    </>
  )
}

SelectColumnFilter.propTypes = {
  column: PropTypes.object,
}

export const StatusPill = ({ value }) => {
  const status = value ? value.toLowerCase() : 'unknown'
  return (
    <div>
      <span
        className={
          classNames(
            'px-3 py-1 uppercase leading-wide font-bold text-xs rounded-sm shadow-sm',
            getStatus(status).cssClassNames
          )
        }
      >
      {(status && (status === "processing" || status === "error")) ? (
        <span>
          {(status === "processing") ? (
            <span data-tip="Please wait -<br />the scan is in processing<br />and should be finished in a few minutes">{getStatus(status).textToDisplay}</span>
          ) : (
            <span data-tip="Please contact support">{getStatus(status).textToDisplay}</span>
          )}
          <ReactTooltip backgroundColor="#b2baff" textColor="#0015ba" html={true} />
        </span>
      ) : (
        <span>{getStatus(status).textToDisplay}</span>
      )}
      </span>
    </div>
  )
}
StatusPill.propTypes = {
  value: PropTypes.string,
}

export const AvatarCell = ({ value, column, row }) => {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="h-10 w-10 rounded-full" src={row.original[column.imgAccessor]} alt="" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-gray-500">{row.original[column.emailAccessor]}</div>
      </div>
    </div>
  )
}

AvatarCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  column: PropTypes.object,
  row: PropTypes.object,
}

export const UnixtimeDateCell = ({ value, column, row }) => {
  // <div className="text-sm text-gray-500">{dateTimeFormat(value)}</div>
  // <div className="text-sm text-gray-500">{dateToString(value)}</div>
  return (
    <div className="text-md text-gray-700">{toISOString(value)}</div>
  )
}

UnixtimeDateCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  column: PropTypes.object,
  row: PropTypes.object,
}

const Table = ({ columns, data, onReloadData = fn }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    pageIndex: pageIndexRedux,
    pageSize: pageSizeRedux,
    status,
    selectedTask,
    filter: filterRedux,
  } = useSelector(state => state.table)

  const initialState = {
    pageIndex: pageIndexRedux, // set value from redux store
    pageSize: pageSizeRedux,
    sortBy: [{ id: 'visit_date', desc: true }], // set sort data by date desc
  }

  // if a STATUS filter is saved in redux set this selected filter!
  if (filterRedux) {
    initialState.filters = [{ id: 'status', value: filterRedux }]
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // setAllFilters,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    // Get the state from the instance
    state: { pageIndex, /* pageSize, */ /* filters */ },
  } = useTable({
    columns,
    data,
    initialState,
  },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const PagesNavigation = (elem) => {
    return (
      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        <PageButton
          className="rounded-l-md"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          data-testid={`table.button.page.first.${elem}`}
        >
          <span className="sr-only">First</span>
          <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </PageButton>
        <PageButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          data-testid={`table.button.page.previous.${elem}`}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </PageButton>
        <PageButton
          onClick={() => nextPage()}
          disabled={!canNextPage}
          data-testid={`table.button.page.next.${elem}`}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </PageButton>
        <PageButton
          className="rounded-r-md"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          data-testid={`table.button.page.last.${elem}`}
        >
          <span className="sr-only">Last</span>
          <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </PageButton>
      </nav>
    )
  }

  React.useEffect(() => {
    setPageSize(pageSizeRedux)
  }, [pageSizeRedux, setPageSize])

  React.useEffect(() => {
    dispatch(setPageIndexRedux(pageIndex))
  }, [dispatch, pageIndex])

  const isLoading = status === 'loading'

  const gotoUrl = (url, data) => {
    history.push({
      pathname: url,
      state: data,
    })
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex gap-x-2 items-baseline">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
              column.Filter ? (
                <div className="sm:mt-0" key={column.id}>
                  {column.render('Filter')}
                </div>
              ) : null
            )
          )}
          <div className="rounded-md self-center">
            <Button
              // onClick={loadTasks}
              onClick={onReloadData}
              data-testid="svg.icon.refresh"
              disabled={isLoading}
            >
              <RefreshIcon
                className={classNames(
                  'flip-horizontal w-6 h-6 bg-transparent rounded-md',
                  isLoading ? 'spin-back' : '',
                )}
              />
            </Button>
          </div>
        </div>
        <div className="flex gap-x-2 items-baseline">
          <div className="mt-2">{PagesNavigation('top')}</div>
        </div>
      </div>
      {/* table */}
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, outerI) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup_${outerI}`}>
                      {headerGroup.headers.map((column, i) => (
                        <th
                          scope="col"
                          className="select-none group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          data-testid={`table.head.column_${i}`}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={`headerGroupHeaders_${i}`}
                        >
                          <div className="flex items-center relative">
                            <span className="m-auto">
                              {column.render('Header')}
                            </span>
                            {/* sort direction indicator */}
                            <span className="ml-auto absolute right-0">
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <SortDownIcon data-testid="svg.icon.sortdown" className="w-4 h-4 text-gray-400" />
                                  : <SortUpIcon data-testid="svg.icon.sortup" className="w-4 h-4 text-gray-400" />
                                : (
                                  <SortIcon data-testid="svg.icon.sort" className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                                )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row, rowI) => {
                    prepareRow(row)
                    const id = row?.original?.id
                    const userCanGotoDetailsPage = ['COMPLETED', 'EDITABLE'].includes(row?.values?.status)
                    const isInStatusProcessing = ['PROCESSING'].includes(row?.values?.status)
                    const mouseCursor = userCanGotoDetailsPage ? 'group-hover:cursor-pointer' : 'group-hover:cursor-default'
                    return (
                      <tr
                        onClick={() => {
                          if (userCanGotoDetailsPage) {
                            return gotoUrl(`/task/${id}`, row?.original)
                          }
                        }}
                        className={classNames(
                          'hover:bg-gray-100 group',
                          `${selectedTask?.id}` === `${id}` ? 'bg-green-50' : '',
                          isInStatusProcessing ? 'bg-gray-50' : ''
                        )}
                        {...row.getRowProps()}
                        key={`page_row_${rowI}`}
                      >
                        {row.cells.map((cell, cellI) => {
                          const lastRow = row.cells.length === cellI + 1
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={classNames(
                                'px-6 py-4 whitespace-nowrap relative',
                                mouseCursor
                              )}
                              role="cell"
                              key={`rows_cell_${cellI}`}
                            >
                              {cell.column.Cell.name === "defaultRenderer"
                                ? <div className="text-md text-gray-700">{cell.render('Cell')}</div>
                                : cell.render('Cell')
                              }
                              {(lastRow && userCanGotoDetailsPage) && <SearchIcon className="w-4 h-4 inset-right-middle hidden group-hover:block"/>}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      <div className="py-3 flex items-center justify-between">
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2 items-baseline">
            <span className="text-sm text-gray-700">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Items Per Page</span>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={e => {
                  dispatch(setPageSizeRedux(Number(e.target.value)))
                }}
                data-testid="table.select.pagesize"
              >
                {[5, 10, 20, 25, 50].map(pageSize => (
                  <option key={`show_pagesize_${pageSize}`} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>{PagesNavigation('bottom')}</div>
        </div>
      </div>
    </>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  data: PropTypes.array,
  onReloadData: PropTypes.func,
}

export default Table
