import React from 'react'
import PropTypes from 'prop-types'
import { fn } from '../../utils/utils'
import { ModalSetCenterpoint } from '../modals'

/*

  Edit the centerpoint

*/
const CanvasEditCenterpoint = ({
  width = 400,
  height = 400,
  lineWidth = 1,
  strokeStyle = '#0b0',
  strokeStyleClicked = '#fb0',
  className,
  onClick = fn,
  onSuccess = fn,
  currentSlice = null,
  centerpoint = [],
  bscanPixelSpacing,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const oneMmDiameterIsOutside = React.useRef()
  const canvasRef = React.useRef(null)
  const canvasPos = React.useRef(null)
  const mouseX = React.useRef(null)
  const mouseY = React.useRef(null)
  const animationFrameId = React.useRef()
  const distanceFromMouse = 25
  let frameCount = 0

  const getPosition = (elm) => {
    let xPosition = 0
    let yPosition = 0

    while (elm) {
      xPosition += (elm.offsetLeft - elm.scrollLeft + elm.clientLeft)
      yPosition += (elm.offsetTop - elm.scrollTop + elm.clientTop)
      elm = elm.offsetParent
    }
    return {
      x: xPosition,
      y: yPosition
    }
  }

  React.useLayoutEffect(() => {
    const canvas = canvasRef?.current
    const ctx = canvas?.getContext('2d')

    const isTheOneMmDiameterOutsideOfTheBscanBoundary = () => {
      //console.log("### FUNC CALLED")
      if (centerpoint?.length >= 0 && bscanPixelSpacing.x && bscanPixelSpacing.y) {
        const intersectionOnCurrentSliceinMm = Math.sqrt(Math.pow(1/2, 2) - Math.pow(bscanPixelSpacing.spacingBetweenSlices, 2))
        const intersectionOnCurrentSliceinPixel = (intersectionOnCurrentSliceinMm / bscanPixelSpacing.x)
        if ((mouseX.current - intersectionOnCurrentSliceinPixel) < 0 || (mouseX.current + intersectionOnCurrentSliceinPixel) > width) {
          // 1 mm diameter is outside of the bscan boundary
          oneMmDiameterIsOutside.current = true
        }
      }
    }

    const draw = (_frameCount) => {
      const xPos = mouseX.current
      const yPos = mouseY.current
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      ctx.lineWidth = lineWidth
      if (_frameCount) {
        // draw dashed line
        ctx.beginPath()
        ctx.strokeStyle = strokeStyle
        ctx.setLineDash([100 * Math.sin(_frameCount * 0.02)**2, 25])
        ctx.moveTo(xPos, 0)
        ctx.lineTo(xPos, yPos - distanceFromMouse)
        ctx.stroke()
        ctx.beginPath()
        ctx.moveTo(xPos, yPos + distanceFromMouse)
        ctx.lineTo(xPos, ctx.canvas.height)
      } else {
        // draw line
        ctx.beginPath()
        ctx.strokeStyle = strokeStyleClicked
        ctx.setLineDash([0, 0])
        ctx.moveTo(xPos, 0)
        ctx.lineTo(xPos, ctx.canvas.height)
      }
      ctx.stroke()
    }

    const render = () => {
      frameCount++
      draw(frameCount)
      animationFrameId.current = window.requestAnimationFrame(render)
    }

    const handleMousemove = (e) => {
      mouseX.current = e.clientX - canvasPos.current.x
      mouseY.current = e.clientY - canvasPos.current.y
      // console.log(`Pos: ${mouseX.current}, ${mouseY.current}`)
    }

    const handleMouseout = () => {
      window.cancelAnimationFrame(animationFrameId.current)
    }

    const handleMouseenter = () => {
      canvasPos.current = getPosition(canvas)
      window.cancelAnimationFrame(animationFrameId.current)
      render()
    }

    const handleClick = () => {
      window.cancelAnimationFrame(animationFrameId.current)
      draw()
      isTheOneMmDiameterOutsideOfTheBscanBoundary()
      setModalIsOpen(true)
    }

    if (canvas) {
      canvasPos.current = getPosition(canvas)
      canvas.addEventListener('mousemove', handleMousemove, false)
      canvas.addEventListener('mouseout', handleMouseout, false)
      canvas.addEventListener('mouseenter', handleMouseenter, false)
      canvas.addEventListener('click', handleClick, false)
      render()

      return () => {
        if (canvas) {
          canvas.removeEventListener('mousemove', handleMousemove, false)
          canvas.removeEventListener('mouseout', handleMouseout, false)
          canvas.removeEventListener('mouseenter', handleMouseenter, false)
          canvas.removeEventListener('click', handleClick, false)
        }
      }
    }
  }, [
    bscanPixelSpacing,
    centerpoint,
    frameCount,
    lineWidth,
    strokeStyle,
    strokeStyleClicked,
    modalIsOpen,
    width,
  ])

  return (
    <>
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        className={className}
      ></canvas>
      <ModalSetCenterpoint
        open={modalIsOpen}
        onCancel={() => {
          setTimeout(() => {
            oneMmDiameterIsOutside.current = false
          }, 200)
          setModalIsOpen(false)
        }}
        onSuccess={() => {
          setTimeout(() => {
            oneMmDiameterIsOutside.current = false
          }, 200)
          onSuccess()
        }}
        data={{
          xPos: mouseX.current,
          slice: currentSlice,
          isOutside: oneMmDiameterIsOutside.current,
        }}
      />
    </>
  )
}

CanvasEditCenterpoint.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  strokeStyle: PropTypes.string,
  strokeStyleClicked: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
  centerpoint: PropTypes.array,
  currentSlice: PropTypes.number,
  bscanPixelSpacing: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    spacingBetweenSlices: PropTypes.number,
  }),
}

export default CanvasEditCenterpoint
