import React, { useState, /*useRef,*/ /*useEffect,*/ /*useMemo,*/ } from 'react'
import { /*useSelector,*/ useDispatch } from 'react-redux'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import {
  Auth,
  // API
} from 'aws-amplify'
import ModalError from '../modals/error/ModalError'
import SvgLoader from '../svg/Loader'
import {
  // resetToken,
  setToken,
  setUser,
} from './store/userSlice'
import ResetPassword from './ResetPassword'
import VerifyCode from './VerifyCode'
import SetPassword from './SetPassword'
import './styles.scss'

const Login = () => {
  Auth.configure({storage: sessionStorage})
  localStorage.clear()

  const dispatch = useDispatch()
  const [isPwReadable, setIsPwReadable] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isFormFieldError, setIsFormFieldError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // 'Login', 'ResetPassword', 'VerifyCode', 'SetPassword'
  const [showContent, setShowContent] = useState({type: 'Login'})
  // const [showContent, setShowContent] = useState({type: 'VerifyCode'})
  // const [showContent, setShowContent] = useState({type: 'SetPassword'})

  // const token = useSelector((state) => state.user.token)
  // const [fields, handleFieldChange] = useFormFields({
  //   email: '',
  //   password: ''
  // })

  // console.log("### Login")
  // console.log(token)

  // const refPassword = useRef(null)

  const {
    register,
    handleSubmit,
    // watch,
    // formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // console.log("##### onSubmit")
    // console.log(data)
    // console.log("##### /onSubmit")
    setIsLoading(true)

    try {
      const userObj = await Auth.signIn(data.username, data.password)
      console.log("### SUCCESS")
      console.log(userObj)
      if (
           userObj?.challengeName === 'NEW_PASSWORD_REQUIRED'
        || userObj?.challengeName === 'FORCE_CHANGE_PASSWORD'
      ) {
        /*
          AWS stuff: If a user is added Cognito requires to set a new one.
        */
        // const { requiredAttributes } = userObj.challengeParam

        // console.log("### -> NEW_PASSWORD_REQUIRED")
        // console.log(requiredAttributes)
        // console.log("----------------------------")

        setIsLoading(false)
        return setShowContent({
          type: 'SetPassword',
          data: {
            user: userObj,
          }
        })
      }
      const user = await Auth.currentAuthenticatedUser()
      console.log("### USER")
      console.log(user)
      const token = user?.signInUserSession?.idToken?.jwtToken
      // console.log("### TOKEN")
      // console.log(token)
      // console.log("### PING?")

      // API way?
      //   // .get('RetinSightAPIGatewayAPI', '/ping')
      //   .get('https://olwi076q83.execute-api.eu-central-1.amazonaws.com/pingdb', '/')
      //   .then(response => {
      //     // Add your code here
      //     console.log("------ RESPONSE")
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     console.log("------ ERROR")
      //     console.log(error.response);
      //   }
      // )

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token,
      }

      const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/user`

      console.log('##### BEFORE FETCH')
      console.log(url)
      // const response = await fetch(url, {
      //   headers,
      //   // method: 'POST',
      //   method: 'GET',
      //   crossDomain: true,
      //   // body: JSON.stringify(datatToSend),
      // }).catch(e => {
      //   console.log("#### ERROR FETCH!")
      //   console.log(e)
      // })
      let response
      try {
        response = await fetch(url, {
          headers,
          // method: 'POST',
          method: 'GET',
          crossDomain: true,
          // body: JSON.stringify(datatToSend),
        })
      } catch (e) {
        console.log("#### ERROR FETCH!")
        console.log(e)
      }

      console.log('##### AFTER FETCH')
      const userData = await response.json()
      console.log("### FETCH userData:")
      console.log(userData)
      if (userData) {
        console.log("### SET USER from login")
        console.log(userData)
        // console.log("### TOKEN")
        // console.log(token)

        // setUser
        // throw Error('Invalid user object')
        const setUserData = {
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
          site: userData.site,
          studies: userData.studies,
        }
        
        dispatch(setUser(setUserData))
        dispatch(setToken(token))
      } else {
        setIsLoading(false)
        throw Error('Invalid user object')
      }

    } catch (e) {
      // onError(e);
      // setIsLoading(false);
      console.log("### ERROR")
      console.log(e)
      console.log(e.code)
      console.log(e.message)
      // e.code could be "NotAuthorizedException" or 500 or 404 or...
      setIsLoading(false)
      setIsError(true)
    }
    return false
  }

  const validateField = (event) => {
    const fieldContent = event.target.value
    if (!fieldContent || (fieldContent.length <= 0)) {
      setIsFormFieldError(true)
    } else {
      setIsFormFieldError(false)
    }
   }

  const RenderLogin = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2" action="#" method="POST">
        <h2 className="select-none mt-0 mb-6 text-center text-2xl font-bold text-gray-900">Sign in to your account</h2>
        <div>
          <label htmlFor="username" className="select-none block text-sm font-medium text-gray-400">
            Username
          </label>
          <div className="mt-1 shadow-md">
            <input
              {...register('username', { required: true })}
              readOnly={isLoading}
              className="appearance-none block w-full p-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-md"
              placeholder="Username"
              data-testid="login.input.username"
              onBlur={validateField}
            />
          </div>
        </div>

        <div className="relative w-full mb-3">
          <label htmlFor="password" className="select-none text-sm text-gray-400">Password</label>
          <input
            {...register('password', { required: true })}
            name="password"
            readOnly={isLoading}
            className="appearance-none border border-gray-300 p-4 placeholder-gray-400 text-black bg-white rounded text-md shadow-md focus:outline-none focus:ring-indigo-500 w-full"
            placeholder="Password"
            type={isPwReadable ? "text" : "password"}
            data-testid="login.input.pw"
            onBlur={validateField}
          />
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            onClick={() => setIsPwReadable(!isPwReadable)}
            data-testid="login.input.pw.eye"
          >
            <p className="group mt-5 cursor-pointer">
              {isPwReadable
                ? <EyeOffIcon data-testid="svg.icon.eye_off" className="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-indigo-700" aria-hidden="true" />
                : <EyeIcon data-testid="svg.icon.eye" className="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-indigo-700" aria-hidden="true" />
              }
            </p>
          </div>
        </div>

        <div className="text-right">
          <a
            onClick={() => setShowContent({type: 'ResetPassword'})}
            className="cursor-pointer text-sm text-blue-300 hover:underline"
            data-testid="login.link.forgot_password"
          >
            Forgot Password?
          </a>
        </div>

        <div className="pt-0">
          <button
            type="submit"
            disabled={isLoading || isFormFieldError}
            className="select-none w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-12 disabled:opacity-50"
            data-testid="login.button.sign_in"
          >
            { isLoading
              ? <SvgLoader />
              : `Sign in`
            }
          </button>
        </div>
        <ModalError
          open={isError}
          onClose={() => setIsError(false)}
          title="Authentication error"
          content="There was an error. Please verify your username and/or your password. If the error still occurs please get in contact with the support."
        />
        <ModalError
          open={isFormFieldError}
          onClose={() => setIsFormFieldError(false)}
          title="Input error"
          content="Please use a valid e-mail address / password."
        />
      </form>
    )
  }

  const handleCodeIsSentCallback = (email) => {
    console.log("---- LOGIN handleCodeIsSentCallback emailAddress")
    console.log(email)
    setShowContent({
      type: 'VerifyCode',
      data: {
        email,
      }
    })
  }

  const showLoginPage = () => {
    setShowContent({type: 'Login'})
  }

  const RenderSwitch = () => {
    switch(showContent.type) {
      case 'VerifyCode':
        return (
          <VerifyCode
            emailAddress={showContent?.data?.email}
            codeIsVerified={showLoginPage}
          />
        )

      case 'ResetPassword':
        return (
          <ResetPassword
            goBack={showLoginPage}
            codeIsSentCallback={handleCodeIsSentCallback}
          />
        )

      case 'SetPassword':
        return (
          <SetPassword
            onSuccess={showLoginPage}
            user={showContent?.data?.user}
          />
        )

      case 'Login':
      default:
        return <RenderLogin />
    }
  }

  return (
    <div className="min-h-screen flex flex-col justify-center pt-0 pb-10 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-3xl sm:rounded-lg sm:px-10 relative">
          <RenderSwitch />
        </div>
      </div>
    </div>
  )
}

export default Login
