import React from 'react'
import {
  PhoneIcon,
  MailIcon,
} from '@heroicons/react/outline'

const Footer = () => {
  return (
    <div className="block fixed inset-x-0 bottom-0 z-10 bg-gray-800 px-4 sm:px-6 lg:px-8 w-full">
    <div className="max-w-7xl mx-auto px-8">
      <div className="flex items-center justify-between h-16 text-white text-xs">
        <div className="flex items-center">
          Copyright © 2021 – All rights reserved RetInSight GmbH | AI-based CST Decision Support Tool
        </div>
        <div className="flex items-center">
          Technical Support: <PhoneIcon className="h-4 w-4 text-xs ml-2 mr-1" /> <a href="tel:+43015813881" className="underline">+43 (0)1 5813 881</a>
          <MailIcon className="h-4 w-4 text-xs ml-2 mr-1" /> <a href="mailto: support@retinsight.com" className="underline">support@retinsight.com</a>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Footer