import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
// import Breadcrumb from '../components/breadcrumb'

const LayoutMain = ({children}) => {
  const userData = useSelector((state) => state.user.data)

  return (
    <>
      <div className="App-header fixed w-full h-16">
        <Header userData={userData} />
      </div>
      <header className="bg-white shadow-sm pt-16 px-12">
        {/* <Breadcrumb /> */}
      </header>
      <main className="pb-24 pt-0 bg-gray-100 flex-grow">
        {/* <div className="p-8 border-2 border-red-400">In "layouts/Main.js" -> CounterValue: {counterValue}</div> */}
        <div className="max-w-full mx-auto py-1 sm:px-6 lg:px-8">
          <div className="px-4 py-0 sm:px-0">
            <div className="border-0 border-gray-200 rounded-lg">
              {children}
            </div>
          </div>
        </div>
      </main>
      <div className="fixed w-full h-16">
        <Footer />
      </div>
    </>
  )
}

LayoutMain.propTypes = {
  children: PropTypes.element.isRequired
}

export default LayoutMain
