import React from 'react'
import PropTypes from 'prop-types'
// import { useParams, } from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'
import RCSlider from 'rc-slider'
import { fn, /*classNames,*/ } from '../../utils/utils'
import 'rc-slider/assets/index.css'
import './slider.scss'

const Slider = ({
  width = 100,
  height = 400,
  maxItems = 3,
  setCurrentItem,
  onChange = fn,
  onAfterChange = fn,
  centerpointSlice = 0,
  changedBscans=[]
}) => {
  // TODO: css classNames
  // Slider stuff
  const style = {
    float: 'left',
    marginTop: 15,
    width: 160,
    height: height - 15 - 10, // - marginTop - marginBottom
    marginBottom: 20,
    marginLeft: '30%'
  }
  const parentStyle = { overflow: 'hidden' }

  const marks = {}
  // Fix: To be able to click on every scan we need to show it
  for (const key of [...new Array(maxItems).keys()]) {
    marks[key] = maxItems < 90
      ? `${key}`
      : key % 4 === 0
        ? `${key}`
        : ''
  }
  // const middle = Math.floor(maxItems/2)
  // console.log(`-> middle: ${middle}`)
  if (centerpointSlice >= 0) {
    marks[centerpointSlice] = {
      style: {
        color: 'black',
      },
      label:
        <div className="rc-slider-middle">
          <strong>{centerpointSlice}</strong>
        </div>,
    }
  }

  const currentItem = setCurrentItem >= 0 && setCurrentItem < maxItems ? setCurrentItem : centerpointSlice
  const defaultValue = currentItem >= 0 ? currentItem : centerpointSlice

  changedBscans.forEach(bscan => {
    const editedBscan = +bscan

    marks[editedBscan] = {
      style: {
        color: 'black',
      },
      label:
        <div className="rc-slider-edited">
          <strong>{editedBscan}</strong>
        </div>,
    }
  })
  
  if (currentItem) {
    marks[currentItem] = {
      style: {
        color: 'black',
      },
      label:
        <div className="rc-slider-active">
          <strong>{currentItem}</strong>
        </div>,
    }
  }

  return (
    <div style={parentStyle}>
      <div style={style}>
        <RCSlider
          vertical
          min={0}
          max={maxItems-1}
          // reverse={true}
          startPoint={currentItem}
          marks={marks}
          step={null}
          onAfterChange={onAfterChange}
          onChange={onChange}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  )
}

Slider.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  maxItems: PropTypes.number,
  setCurrentItem: PropTypes.number,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  centerpointSlice: PropTypes.number,
  changedBscans: PropTypes.array,
}

export default Slider
