import React from 'react'
import PropTypes from 'prop-types'
// import { classNames } from '../../utils/utils'

/*

  Draw the 1mm diameter around centerpoint

*/
const CanvasOneMmAroundCenterpoint = ({
  width = 400,
  height = 400,
  lineWidth = 1,
  strokeStyle = '#bf0',
  centerpoint = [],
  centerpointSlice,
  currentSlice,
  bscanPixelSpacing = {},
  className,
}) => {
  const canvasRef = React.useRef(null)
  const [context, setContext] = React.useState(null)

  React.useEffect(() => {
    if (!context && canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
    if (context && centerpoint?.length >= 0 && bscanPixelSpacing.x && bscanPixelSpacing.y) {
      const sliceDiff = Math.abs(currentSlice - centerpointSlice)
      const intersectionOnCurrentSliceinMm = Math.sqrt(Math.pow(1/2, 2) - Math.pow(bscanPixelSpacing.spacingBetweenSlices * sliceDiff, 2))
      const intersectionOnCurrentSliceinPixel = (intersectionOnCurrentSliceinMm / bscanPixelSpacing.x)
      
      const oneMmDistance = {
        left: centerpoint[0] - intersectionOnCurrentSliceinPixel,
        right: centerpoint[0] + intersectionOnCurrentSliceinPixel,
      }
      // draw the 1 mm diameter
      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      context.beginPath()
      context.setLineDash([20, 15])
      context.lineWidth = lineWidth
      context.strokeStyle = strokeStyle
      context.moveTo(oneMmDistance.left, 0)
      context.lineTo(oneMmDistance.left, height)
      context.moveTo(oneMmDistance.right, 0)
      context.lineTo(oneMmDistance.right, height)
      context.stroke()
    }
  }, [
    context,
    centerpoint,
    height,
    lineWidth,
    strokeStyle,
    bscanPixelSpacing,
    centerpointSlice,
    currentSlice,
  ])

  // console.log("centerpoint:")
  // console.log(centerpoint)
  if (!centerpoint || centerpoint?.length === 0) {
    return null
  }

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    ></canvas>
  )
}
CanvasOneMmAroundCenterpoint.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  strokeStyle: PropTypes.string,
  centerpoint: PropTypes.array,
  centerpointSlice: PropTypes.number,
  currentSlice: PropTypes.number,
  bscanPixelSpacing: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    spacingBetweenSlices: PropTypes.number,
  }),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}


/*

  Draw the centerpoint

*/
const CanvasCenterpoint = ({
  width = 400,
  height = 400,
  lineWidth = 1,
  strokeStyle = '#fb0',
  centerpoint = [],
  className,
}) => {
  const canvasRef = React.useRef(null)
  const [context, setContext] = React.useState(null)

  React.useEffect(() => {
    if (!context && canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
    if (context && centerpoint.length) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      context.beginPath()
      context.lineWidth = lineWidth
      context.strokeStyle = strokeStyle
      context.moveTo(centerpoint[0], 0)
      context.lineTo(centerpoint[0], height)
      context.stroke()
    }
  }, [context, centerpoint, height, lineWidth, strokeStyle])

  if (centerpoint.length === 0) {
    return null
  }

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    ></canvas>
  )
}
CanvasCenterpoint.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  strokeStyle: PropTypes.string,
  centerpoint: PropTypes.array,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}


/*

  Draw all bscans on the fundus

*/
const CanvasFundusAllBscanLines = ({
  width = 400,
  height = 400,
  lineWidth = 1,
  strokeStyle = '#fb0',
  bscans = [],
  className,
  changedBscans = []
}) => {
  const canvasRef = React.useRef(null)
  const [context, setContext] = React.useState(null)

  React.useEffect(() => {
    if (!context && canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
    if (context && bscans.length) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      context.lineWidth = lineWidth

      bscans.forEach((bscan, i) => {
        context.beginPath()
        context.strokeStyle = (changedBscans.includes(((bscans.length) - 1 - bscan.bscan_number).toString())) ? '#1aff81' : strokeStyle
        /* Milli: Test */
        // console.log(`bscan #: ${bscan?.bscan_number}`)
        // if (bscan?.bscan_number === 0) {
        //   context.strokeStyle = '#00f'
        // } else if (bscan?.bscan_number === bscans.length - 1) {
        //   context.strokeStyle = '#f0f'
        // } else {
        //   context.strokeStyle = strokeStyle
        // }
        /* /Milli: Test */
        context.moveTo(bscan.bscan_xstart, bscan.bscan_ystart)
        context.lineTo(bscan.bscan_xend, bscan.bscan_yend)
        context.stroke()
      })
      /* Milli: TEST */
      // context.beginPath()
      // context.moveTo(0, 0)
      // context.fillStyle = '#0ff'
      // context.fillRect(0, 0, 25, 25) // draw a 25x25 pixel
      // context.stroke()
      /* /Milli: Test */
    }
  }, [context, bscans, lineWidth, strokeStyle, changedBscans])

  if (bscans.length === 0) {
    return null
  }

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    ></canvas>
  )
}
CanvasFundusAllBscanLines.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  strokeStyle: PropTypes.string,
  bscans: PropTypes.array,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  changedBscans: PropTypes.array,
}

/*

  Draw the current bscan on the fundus

*/
const CanvasFundusCurrentBscan = ({
  width = 400,
  height = 400,
  lineWidth = 1,
  strokeStyle = '#f00',
  currentSelectedBscanPosition: {
    bscan_xstart = null,
    bscan_xend = null,
    bscan_ystart = null,
    bscan_yend = null,
  } = {},
  className,
}) => {
  const canvasRef = React.useRef(null)
  const [context, setContext] = React.useState(null)

  React.useEffect(() => {
    if (!context && canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
    if (context && bscan_xstart) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      context.lineWidth = lineWidth
      context.strokeStyle = strokeStyle
      context.beginPath()
      context.moveTo(bscan_xstart, bscan_ystart)
      context.lineTo(bscan_xend, bscan_yend)
      context.stroke()
    }
  }, [context, bscan_xstart, bscan_xend, bscan_ystart, bscan_yend,lineWidth, strokeStyle])

  if (!bscan_xstart) {
    return null
  }

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    ></canvas>
  )
}
CanvasFundusCurrentBscan.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  strokeStyle: PropTypes.string,
  currentSelectedBscanPosition: PropTypes.shape({
    bscan_xstart: PropTypes.number,
    bscan_xend: PropTypes.number,
    bscan_ystart: PropTypes.number,
    bscan_yend: PropTypes.number,
  }),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}




/*

  Draw the annotations

*/
const Canvas = ({
  width = 400,
  height = 400,
  lineWidth = 1,
  className,
  annotation,
  children,
}) => {
  const canvasRef = React.useRef(null)
  const [context, setContext] = React.useState(null)

  React.useEffect(() => {
    if (!context && canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
    if (context && annotation) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      context.beginPath()
      const color = `#${((1<<24)*Math.random() | 0).toString(16)}` // random color
      // context.fillStyle = color
      context.strokeStyle = color
      if (annotation) {
        if (annotation.biomarker && annotation.biomarker.color) {
          // context.fillStyle = annotation.biomarker.color
          context.strokeStyle = annotation.biomarker.color
        }
        if (annotation.data && annotation.data.length > 0) {
          annotation.data.forEach((data, i) => {
            // context.fillRect(i, data, 1, 1) // draw a 1x1 pixel
            context.lineTo(i, data) // draw a line
          })
          context.lineWidth = lineWidth
          context.stroke()
        }
      }
    }
  }, [context, annotation, lineWidth])

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    ></canvas>
  )
}
Canvas.propTypes = {
  annotation: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

/*

  Process all annotations

*/
const CanvasAnnotations = ({
  annotations = [],
  width,
  height,
  lineWidth = 1,
}) => {
  if (annotations?.length < 1) {
    return null
  }

  return (
    <div
      style={{
        width,
        height,
      }}>
      {annotations.map((annotation, i) => {
        return (
          <Canvas
            key={`canvas_layer_${i}`}
            width={width}
            height={height}
            annotation={annotation}
            className="absolute inset-0"
            lineWidth={lineWidth}
          />
        )
      })}
    </div>
  )
}

CanvasAnnotations.propTypes = {
  annotations: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
}

/*

  Draw the centerpoint slice on the fundus

*/
const CanvasFundusCenterpointLine = ({
  width = 400,
  height = 400,
  lineWidth = 1,
  className,
  strokeStyle = '#bf0',
  bscanPositionOnFundus,
  centerpointXposOnBscan,
  children,
}) => {
  const canvasRef = React.useRef(null)
  const [context, setContext] = React.useState(null)

  const { xstart, xend, y } = bscanPositionOnFundus || {}

  React.useEffect(() => {
    const canvas = canvasRef.current
    if (!context && canvas) {
      const renderCtx = canvas.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
    if (context) {
      // draw line of the bscan where the centerpoint is placed
      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      context.beginPath()
      context.strokeStyle = strokeStyle
      context.lineWidth = lineWidth
      context.moveTo(xstart, y)
      context.lineTo(xend, y)
      context.stroke()
      // draw centerpoint
      context.beginPath()
      context.fillStyle = '#ff0'
      context.fillRect(xstart + centerpointXposOnBscan, y, 1, 1) // draw a 1x1 pixel
      context.stroke()
    }
  }, [context, xstart, xend, y, centerpointXposOnBscan, lineWidth, strokeStyle])

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    ></canvas>
  )
}
CanvasFundusCenterpointLine.propTypes = {
  bscanPositionOnFundus: PropTypes.shape({
    xstart: PropTypes.number,
    xend: PropTypes.number,
    y: PropTypes.number,
  }),
  centerpointXposOnBscan: PropTypes.number,
  strokeStyle: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

export {
  CanvasOneMmAroundCenterpoint,
  CanvasFundusCenterpointLine,
  CanvasFundusAllBscanLines,
  CanvasFundusCurrentBscan,
  CanvasCenterpoint,
  CanvasAnnotations,
}
