import { createSlice } from '@reduxjs/toolkit'
// import {
//   Auth,
//   // API
// } from 'aws-amplify'
// import { useDispatch } from 'react-redux'
/*

API:

"/app/user", POST:
{
  email: <value>
}
"/internal", GET: return "hello world"

"/app/ping", GET: return "pong"
"/app/pingdb", GET: return json stuff

*/

const initialState = {
  token: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  data: {
    email: null,
    first_name: null,
    last_name: null,
    site: null,
    studies: null,
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    resetToken: (state) => {
      // TODO: sign out?
      state.token = null
      sessionStorage.clear()
    },
    setUser: (state, action) => {
      const {
        email,
        first_name,
        last_name,
        site,
        studies
      } = action.payload
      state.data.email = email
      state.data.first_name = first_name
      state.data.last_name = last_name
      state.data.site = site
      state.data.studies = studies
    },
    resetUser: (state) => {
      state.data = {
        email: null,
        first_name: null,
        last_name: null,
        site: null,
        studies: null,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setToken,
  resetToken,
  setUser,
  resetUser,
} = userSlice.actions

export default userSlice.reducer
