import React from 'react'
import PropTypes from 'prop-types'
import { classNames, fn } from '../../utils/utils'

const AnnotationLayer = ({
  annotations = [],
  layerNamesToHide = [],
  showAnnotationGroupsOnly = false,
  annotationEditModeIsOn = false,
  annotationLayerNameToShow = '',
  onClick = fn,
}) => {
  /*
  type: "layer", biomarker: {name: "xyz", id: 123, description: "..."}, color: '#00ffff', data:
  */
  // console.log("#### AnnotationLayer: annotations")
  // console.log(annotations)
  if (annotations.length === 0) {
    return null
  }
  const allLayersNames = []
  if (annotations && annotations.length > 0) {
      annotations.forEach((item, i) => {
        if (item.biomarker?.name) {
          allLayersNames.push(item.biomarker.name)
        }
      })
  }
  if (showAnnotationGroupsOnly) {
    return (
      <div>
        {annotations.map((elm, i) => {
          const biomarkerName = elm.biomarker?.name
          // const isInactive = layerNamesToHide.includes(biomarkerName)
          let isInactive
          if (annotationEditModeIsOn) {
            isInactive = biomarkerName !== annotationLayerNameToShow
          } else {
            isInactive = layerNamesToHide.includes(biomarkerName)
          }
          const colorName = 'text-pink-600'
          const bgColorName = 'bg-pink-600'

          const backgroundColor = (!isInactive && elm.biomarker.color) || '#aaa'
          return (
            <div
              className={classNames(
                'border-2 hover:border-gray-500 cursor-pointer inline-flex items-center bg-white leading-none rounded-full shadow text-teal text-sm mx-1 select-none',
                colorName,
                isInactive && 'opacity-50',
              )}
              key={`elm_biomarker_${i}`}
              style={{backgroundColor}}
              onClick={() => {
                onClick(biomarkerName)
              }}
              data-testid={`annotationlayer.group.biomarker.${biomarkerName}`}
            >
              <span
                className={classNames(
                  'inline-flex text-white rounded-full h-6 justify-center items-center font-light',
                  bgColorName,
                )}
              >
                {/* <span
                  aria-hidden="true"
                  className={classNames(
                    elmIsEnabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                /> */}
              </span>
              <span className="drop-shadow-sm inline-flex px-2 font-normal text-white font-bold">{biomarkerName}</span>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div>Show annotation LAYER</div>
  )
}

AnnotationLayer.propTypes = {
  annotations: PropTypes.array,
  layerNamesToHide: PropTypes.array,
  showAnnotationGroupsOnly: PropTypes.bool,
  annotationEditModeIsOn: PropTypes.bool,
  annotationLayerNameToShow: PropTypes.string,
  onClick: PropTypes.func,
}

export default AnnotationLayer
