import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import {
  // ExclamationIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid'
import { RefreshIcon, ExclamationIcon, } from '@heroicons/react/outline'
import { useDispatch, /*useSelector*/ } from 'react-redux'
import { fn } from '../../utils/utils'
// import { timeout, } from '../../utils/utils'
import { postAnnotations } from '../../components/table/store/tableSlice'

const ModalSaveAnnotations = ({
  open = false,
  onCancel = fn,
  onSuccess = fn,
  data: {
    newAnnotations = null,
    width,
    height,
    changedBscans,
  },
}) => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [isSucceeded, setIsSucceeded] = React.useState(false)
  const buttonRefOk = React.useRef()
  const buttonRefCancel = React.useRef()
  const dispatch = useDispatch()

  const onSave = async () => {
    setIsProcessing(true)
    // await timeout(2000)
    const result = await dispatch(postAnnotations({
      annotations: newAnnotations,
      width,
      height,
    }))
    // console.log("### MODAL onConfirm result")
    // console.log(result)
    if (result?.payload?.isError === true) {
      setIsError(true)
    } else {
      setIsSucceeded(true)
    }
    setIsProcessing(false)
  }

  const onSucceeded = () => {
    onClose()
    onSuccess()
  }

  const onClose = () => {
    reset()
    onCancel()
  }

  const reset = () => {
    setTimeout(() => {
      setIsError(false)
      setIsProcessing(false)
      setIsSucceeded(false)
      // setCstValue(null)
    }, 200)
  }

  const title = isError
    ? 'Error!'
    : isSucceeded
      ? 'Success'
      : isProcessing
        ? `Save edited annotations`
        : `Save edited annotations`

  const content = isError
    ? (
      <div>
        <h3>There was an error to save the new annotations.</h3>
        <strong>Please contact the support!</strong>
      </div>
    )
    : isSucceeded
      ? (
        <div>
          <h3>The new annotations were successfully saved.</h3>
        </div>
      )
      : isProcessing
        ? (
          <div>
            {/* <h3 className="mb-2">The PDF is beeing generated.</h3> */}
            <strong className="text-black">Please wait...</strong>
          </div>
        )
        : (
          <div>
            <h3>{`Save the edited annotations for ${changedBscans.length > 1 ? ` ${changedBscans.length} `:''}scan${changedBscans.length > 1 ? 's':''} at ${changedBscans.sort((a, b) => a - b).join(', ')}?`}</h3>
          </div>
        )

  const icon = isError
    ? <ExclamationIcon className="h-20 w-20 text-red-600" aria-hidden="true" />
    : isSucceeded
      ? <CheckCircleIcon className="h-20 w-20 text-green-600" aria-hidden="true" />
      : isProcessing
        ? <RefreshIcon className="spin-back flip-horizontal w-12 h-12 bg-transparent rounded-md" />
        : <QuestionMarkCircleIcon className="h-20 w-20 text-blue-600" aria-hidden="true" />

  const button = isError
    ? (
      <button
        type="button"
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={() => onClose(true)}
        ref={buttonRefOk}
        data-testid="modal.question.button.error.ok"
        // disabled={isProcessing}
      >
        Ok
      </button>
    )
    : isSucceeded
      ? (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onSucceeded}
          ref={buttonRefOk}
          data-testid="modal.question.button.succeeded.ok"
          // disabled={isProcessing}
        >
          Ok
        </button>
      )
      : (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={onClose}
            ref={buttonRefCancel}
            data-testid="modal.question.button.cancel"
            disabled={isProcessing}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={onSave}
            ref={buttonRefOk}
            data-testid="modal.question.button.save"
            disabled={isProcessing}
          >
            Save
          </button>
        </>
    )

  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={buttonRefCancel}
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-3xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="bg-white p-8">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-20 w-20 rounded-full bg-white sm:mx-0 sm:h-20 sm:w-20 -mt-4">
                    {/* <ExclamationIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
                    {/* <QuestionMarkCircleIcon className="h-20 w-20 text-blue-600" aria-hidden="true" /> */}
                    {icon}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="text-md text-gray-500">
                        {content}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row justify-end mb-2">
                {button}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

ModalSaveAnnotations.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  data: PropTypes.exact({
    newAnnotations: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    changedBscans: PropTypes.array,
  }),
}

export default ModalSaveAnnotations
