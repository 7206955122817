import React from "react"
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { LayoutMain, LayoutDashboard } from './layouts'
import PrivateRoute from './utils/PrivateRoute'
import PageDashboard from './pages/dashboard'
import PageTask from './pages/task'

const Routes = () => (
  <Switch>
    <PrivateRoute path="/task/:taskId" exact>
      <LayoutMain>
        <PageTask />
      </LayoutMain>
    </PrivateRoute>
    <PrivateRoute exact path="/">
      <LayoutDashboard>
        <PageDashboard />
      </LayoutDashboard>
    </PrivateRoute>
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
)

export default Routes
