import React from 'react'
import PropTypes from 'prop-types'
import Routes from './Routes'
import './styles/App.css'

import {
  BrowserRouter, // for server (content will be served)
  HashRouter, // for static deployment (open index.html)
} from 'react-router-dom'

const App = ({
  buildType = 'server'
}) => {
  const appBuildType = process.env.REACT_APP_BUILDTYPE || buildType
  const Router = appBuildType === 'static'
    ? HashRouter
    : BrowserRouter

  return (
    <Router>
      <div className="App">
        {/* <h1>REACT_APP_ENV_TEST: {process.env.REACT_APP_ENV_TEST}</h1> */}
        <Routes />
      </div>
    </Router>
  )
}

App.propTypes = {
  buildType: PropTypes.oneOf(['static', 'server', undefined]),
}

export default App
