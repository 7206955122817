import React from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowLeftIcon, /* SaveIcon */ } from '@heroicons/react/outline'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip'
import InfoBox from '../../components/infobox'
import { Button } from '../../components/table/Button'
import RcSlider from '../../components/slider'
import AnnotationLayer from '../../components/annotationlayer'
import EtdrsGrid from '../../components/etdrs-grid'
import {
  CanvasOneMmAroundCenterpoint,
  CanvasCenterpoint,
  CanvasAnnotations,
  CanvasFundusCenterpointLine,
  CanvasFundusAllBscanLines,
  CanvasFundusCurrentBscan,
  CanvasEditCenterpoint,
  CanvasEditAnnotations,
} from '../../components/canvas'
import {
  fetchTaskByIdAsync,
  fetchScanByScanIdAsync,
  fetchAllImagesByScanIdAsync,
  getCenterpoint,
  getCenterpointBscanForFundus,
  getBscanPixelSpacing,
  getFundusPixelSpacing,
} from '../../components/table/store/tableSlice'
import { classNames, toISOString } from '../../utils/utils'
import RouteLeavingGuard from '../../utils/RouteLeavingGuard'
import ModalCalculateCstValue from './ModalCalculateCstValue'
import ModalSaveAnnotations from './ModalSaveAnnotations'
import ModalWarnAboutChangedAnnotations from './ModalWarnAboutChangedAnnotations'
import 'rc-slider/assets/index.css'
import './PageTask.scss'


const PageTask = ({
  children = null,
}) => {
  const history = useHistory()
  const { taskId = null } = useParams()
  const dispatch = useDispatch()
  const {
    selectedTask = null,
    allImagesByScanId,
    scanByScanId,
    status,
  } = useSelector(state => state.table)
  const vrc_ticket_id = selectedTask?.vrc_ticket_id
  const {
    bscan_width = null,
    bscan_height = null,
    fundus_width = null,
    fundus_height = null,
  } = scanByScanId || {}
  const [ layerNamesToHide, setLayerNamesToHide ] = React.useState([])
  const [ annotationHasChanged, setAnnotationHasChanged ] = React.useState(false)
  const maxImageIndex = (
    allImagesByScanId?.bscans?.length &&
    (Number(selectedTask?.id) === Number(taskId))) ? allImagesByScanId.bscans.length : 0
  const [ imageIndex, setImageIndex ] = React.useState(0) // imageIndex

  React.useEffect(() => {
    setImageIndex(Math.floor(maxImageIndex/2))
  }, [maxImageIndex])

  const bscanImageIndex = `${imageIndex}` // display b-scan position
  const [ modalCalculateCstValueIsOpen, setModalCalculateCstValueIsOpen ] = React.useState(false)
  const [ modalSaveAnnotationsIsOpen, setModalSaveAnnotationsIsOpen ] = React.useState(false)
  const [ centerpointEditModeIsOn, setCenterpointEditModeIsOn ] = React.useState(false)
  const [ modalWarnAboutChangedAnnotationsIsOpen, setModalWarnAboutChangedAnnotationsIsOpen ] = React.useState(false)
  // This is used only when annotationEditModeIsOn
  const [ annotationEditModeIsOn, setAnnotationEditModeIsOn ] = React.useState(false)
  // const [ annotationEditModeDrawingIsOn, setAnnotationEditModeDrawingIsOn ] = React.useState(false)
  const [ currentAnnotationLayerToShow, setCurrentAnnotationLayerToShow ] = React.useState(null)
  const newAnnotations = React.useRef()
  // /This is used only when annotationEditModeIsOn

  React.useEffect(() => {
    dispatch(fetchTaskByIdAsync(taskId))
  }, [dispatch, taskId])

  React.useEffect(() => {
    if (selectedTask && selectedTask.scan_id && (Number(selectedTask?.id) === Number(taskId))) {
      dispatch(fetchScanByScanIdAsync(selectedTask.scan_id))
      dispatch(fetchAllImagesByScanIdAsync(selectedTask.scan_id))
    }
  }, [dispatch, selectedTask, taskId])

  const changedBscans = (newAnnotations?.current && Object.keys(newAnnotations?.current).map(bscan => bscan.split('_')[1])) || []

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (newAnnotations?.current && Object.keys(newAnnotations?.current)?.length > 0) {
        event.preventDefault()
        return event.returnValue = 'x'
      }
      return event
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [history, newAnnotations])

  const bscanGoUp = React.useCallback(() => {
    if (imageIndex < (maxImageIndex - 1)) {
      setImageIndex(imageIndex + 1)
    }
  }, [imageIndex, maxImageIndex])

  const bscanGoDown = React.useCallback(() => {
    if (imageIndex > 0 && imageIndex < maxImageIndex) {
      setImageIndex(imageIndex - 1)
    }
  }, [imageIndex, maxImageIndex])

  const mouseIsOverFundusAndBscanContainer = React.useRef(false)

  React.useEffect(() => {
    const keyEventsHandler = e => {
      if (false === mouseIsOverFundusAndBscanContainer.current) {
        return
      }
      switch (e.key) {
        case 'ArrowUp':
          bscanGoUp()
          break
        case 'ArrowDown':
          bscanGoDown()
          break
        default:
      }
    }

    window && window.addEventListener('keydown', keyEventsHandler)
    return () => {
      // Remove event listeners on cleanup
      window && window.removeEventListener('keydown', keyEventsHandler)
    }
  }, [bscanGoUp, bscanGoDown])



  const isLoading = status === 'loading'

  const centerpoint = getCenterpoint()
  const bscanPixelSpacing = getBscanPixelSpacing()
  const centerpointXpos = centerpoint?.[0]
  const centerpointSlice = ((maxImageIndex - centerpoint?.[1]) - 1)
  // Milli: VRC-WORKAROUND: see table/store/tableSlice.js
  const centerpointSliceForBscan = centerpoint?.[3]
  const centerpointBscanForFundus = getCenterpointBscanForFundus()
  const fundusPixelSpacing = getFundusPixelSpacing()

  const onWheel = e => {
    if (e.deltaX < 0 || e.deltaX > 0) {
      // wheel event left or right
      return
    }
    if (e.deltaY > 0) {
      // wheel event up
      if (imageIndex > 0) {
        setTimeout(() => {
          // console.log("--- up")
          setImageIndex(imageIndex - 1)
        }, 30)
      }
    }
    if (e.deltaY < 0) {
      // wheel event down
      if (imageIndex < (maxImageIndex - 1)) {
        setTimeout(() => {
          // console.log("--- down")
          setImageIndex(imageIndex + 1)
        }, 30)
      }
    }
  }

  const ButtonsUpDown = ({ testId = 'page.task.slider.buttons' }) => (
    <>
      <button
        type="button"
        className="disabled:opacity-30 relative inline-flex items-center px-2 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 active:bg-gray-200"
        onClick={bscanGoUp}
        data-testid={`${testId}.up`}
      >
        <ArrowUpIcon className="h-5 w-3 text-gray-400" aria-hidden="true" />
      </button>
      <button
        type="button"
        className="disabled:opacity-30 relative inline-flex items-center px-2 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 active:bg-gray-200"
        onClick={bscanGoDown}
        data-testid={`${testId}.down`}
      >
        <ArrowDownIcon className="h-5 w-3 text-gray-400" aria-hidden="true" />
      </button>
    </>
  )
  ButtonsUpDown.propTypes = {
    testId: PropTypes.string,
  }

  const Slider = ({
    height = 410,
    centerpointSlice,
    changedBscans,
  }) => (
    <div>
      <ButtonsUpDown testId="page.task.slider.button.top" />
      <RcSlider
        height={height}
        setCurrentItem={imageIndex}
        onAfterChange={value => {
          // console.log(value)
          // setSliderCurrentItem(value)
          setImageIndex(value)
        }}
        onChange={value => {
          // console.log(`## onChange: ${value}`)
          // setTimeout(() => {
          //   value > 0 && value < maxImageIndex && setImageIndex(value)
          // },50)
        }}
        maxItems={maxImageIndex}
        // maxItems={41}
        centerpointSlice={centerpointSlice}
        changedBscans={changedBscans}
      />
      <ButtonsUpDown testId="page.task.slider.button.bottom" />
    </div>
  )
  Slider.propTypes = {
    height: PropTypes.number,
    centerpointSlice: PropTypes.number,
    changedBscans: PropTypes.array,
  }

  const toggleLayer = layerName => {
    if (annotationEditModeIsOn) {
      // Only show selected in Edit Mode
      setCurrentAnnotationLayerToShow(layerName)
    } else {
      // Normal behaviour
      const newLayerNamesToHide = layerNamesToHide.includes(layerName)
        ? layerNamesToHide.filter(item => item !== layerName)
        : [ ...layerNamesToHide, layerName ]
      setLayerNamesToHide(newLayerNamesToHide)
    }
  }

  // OLD:
  // const currentBscanToShow = allImagesByScanId?.bscans?.[imageIndex]
  //
  // CTS-1028: Fix for RC type of usage (They don't use the Dicom standard!)
  /*
  const bscanNoToShow = Math.abs(maxImageIndex-1-imageIndex)
  const currentBscanToShow = allImagesByScanId?.bscans?.filter(bscan => bscan.bscan_number === bscanNoToShow)?.[0]
  */
  // New way with bscan_number_vrc. ...is set in store/tableSlice.js
  const currentBscanToShow = allImagesByScanId?.bscans?.filter(bscan => bscan.bscan_number_vrc === imageIndex)?.[0]

  const currentSelectedBscan = currentBscanToShow
  const currentSelectedBscanPosition = {
    bscan_xstart: currentBscanToShow?.bscan_xstart,
    bscan_xend: currentBscanToShow?.bscan_xend,
    bscan_ystart: currentBscanToShow?.bscan_ystart,
    bscan_yend: currentBscanToShow?.bscan_yend,
  }

  // console.log('#### currentSelectedBscan')
  // console.log(currentSelectedBscan)
  // console.log(`file_id     : ${currentSelectedBscan?.annotations?.[0]?.file_id}`)
  // console.log(`biomarker_id: ${currentSelectedBscan?.annotations?.[0]?.biomarker_id}`)

  // // OLD: const annotationsToShow = allImagesByScanId?.bscans?.[imageIndex]?.annotations
  const annotationsToShow = currentSelectedBscan?.annotations

  let annotationsToShowFiltered = annotationsToShow
  const firstAnnotationToShow = annotationsToShow && annotationsToShow?.[0]?.biomarker?.name

  if (annotationsToShow?.length > 0 && layerNamesToHide?.length > 0) {
    annotationsToShowFiltered = annotationsToShow.filter((item) => {
      return layerNamesToHide.indexOf(item.biomarker?.name) < 0
    })
  }

  const ContentTop = () => {
    return (
      <div as="nav" className="w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mt-4 mx-auto bg-white w-full mx-3 flex flex-row">
            <div className="flex flex-row">
              <div className="mx-10 relative z-0 inline-flex">
                <Button
                  onClick={() => history.goBack()}
                  data-testid="svg.icon.back"
                  className="mx-1 my-2 px-3 bg-white-200 border-white text-white-700 hover:bg-white-500 hover:text-gray-900 rounded-lg"
                >
                  <ArrowLeftIcon
                    className={classNames(
                      'w-6 h-6 bg-transparent border-white',
                    )}
                  />
                </Button>
              </div>
            </div>
            <div className="h-18 w-full flex flex-row-reverse">
              <div className="p-2">
                {selectedTask?.patient_id && (
                  <InfoBox
                    title="Patient ID:"
                    content={selectedTask?.patient_id}
                    colorName="text-pink-600"
                    bgColorName="bg-pink-600"
                  />
                )}
                {selectedTask?.scan_id && (
                  <InfoBox
                    title="VRC Ticket ID:"
                    content={vrc_ticket_id}
                    colorName="text-yellow-600"
                    bgColorName="bg-yellow-600"
                  />
                )}
                {selectedTask?.visit_date && (
                  <InfoBox
                    title="Visit Date:"
                    content={toISOString(selectedTask?.visit_date)}
                    colorName="text-blue-600"
                    bgColorName="bg-blue-600"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isTheOneMmDiameterOutsideOfTheBscanBoundary = () => {
    if (centerpoint?.length >= 0 && bscanPixelSpacing.x && bscanPixelSpacing.y) {
      const intersectionOnCurrentSliceinMm = Math.sqrt(Math.pow(1/2, 2) - Math.pow(bscanPixelSpacing.spacingBetweenSlices, 2))
      const intersectionOnCurrentSliceinPixel = (intersectionOnCurrentSliceinMm / bscanPixelSpacing.x)
      const oneMmDistance = {
        left: centerpoint[0] - intersectionOnCurrentSliceinPixel,
        right: centerpoint[0] + intersectionOnCurrentSliceinPixel,
      }
      if (oneMmDistance.right > bscan_width || oneMmDistance.left < 0) {
        return true
      }
    }
    return false
  }

  const newFundusHeight = bscan_height
  const newFundusWidth = ((newFundusHeight * fundus_width) / fundus_height) || 400

  const ButtonActions = () => {
    const buttonCalculateCstValueIsDisabled = (
      isTheOneMmDiameterOutsideOfTheBscanBoundary()
      || annotationEditModeIsOn
      || centerpointEditModeIsOn
      || isLoading
      || modalCalculateCstValueIsOpen
      || selectedTask?.cst?.data
    )
    return (
      <div className="text-smb g-white text-gray-500 flex flex-row">
        <div className="pr-2 flex flex-row">
          <InfoBox
            title="B-scan"
            content={bscanImageIndex}
            contentClassName="w-2 -ml-4"
          />
          {/* <div>B-scan</div>
          <div className="w-10">{bscanImageIndex}</div> */}
        </div>
        <div className="pr-2 w-full flex flex-row">
          <div className="w-3/5 flex flex-grow">
          {!selectedTask?.cst?.data
            ? (
              <div className="flex flex-grow">
                <Button
                  className={classNames(
                    'rounded-md rounded-r-none border border-r-0 border-transparent shadow-sm px-8 py-2 bg-blue-600 text-base font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-50 active:bg-blue-500 text-white',
                    annotationEditModeIsOn && 'annotation-edit-mode-button',
                  )}
                  onClick={() => {
                    if (!annotationEditModeIsOn) {
                      setAnnotationEditModeIsOn(true)
                      setAnnotationHasChanged(false)
                      // if any annotations have been changed: Show modal to save?
                      // reset changed annotations
                      newAnnotations.current = {}
                    } else {
                      if (changedBscans?.length > 0) {
                        // show modal to warn user about chnaged annotaions
                        setModalWarnAboutChangedAnnotationsIsOpen(true)
                      } else {
                        // no changed annotations
                        setAnnotationEditModeIsOn(false)
                        setAnnotationHasChanged(false)
                        newAnnotations.current = {}
                      }
                    }
                  }}
                  data-testid="button.edit_annotation"
                  disabled={isLoading || centerpointEditModeIsOn}
                  style={{ background: 'blue !important', borderLeft: '10px solid red !important', }}
                >
                  {annotationEditModeIsOn ? 'Cancel Annotation' : 'Edit Annotation'}
                </Button>
                <Button
                  className={classNames(
                    'rounded-md rounded-l-none border border-l-0 border-transparent shadow-sm px-4 py-2 mr-10 bg-blue-600 text-base font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-50 active:bg-blue-500 text-white',
                    annotationEditModeIsOn && 'annotation-edit-mode-button',
                  )}
                  style={{ borderLeftWidth: 1, borderLeftStyle: 'solid', borderLeftColor: '#8babf0'}}
                  onClick={() => {
                    // open modal
                    setModalSaveAnnotationsIsOpen(true)
                  }}
                  data-testid="button.edit_annotation.save"
                  disabled={isLoading || centerpointEditModeIsOn || !annotationHasChanged || !annotationEditModeIsOn}
                  title="Save new annotations..."
                >
                  {/* <SaveIcon
                    className={classNames(
                      'w-6 h-6 bg-transparent border-white m-0 p-0',
                    )}
                  /> */}
                  Save Annotation
                </Button>

                <Button
                  className={classNames(
                    'rounded-md border border-transparent shadow-sm px-8 py-2 bg-pink-600 text-base font-medium hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-pink-50 active:bg-pink-500 text-white',
                    centerpointEditModeIsOn && 'centerpoint-edit-mode-button',
                  )}
                  onClick={() => setCenterpointEditModeIsOn(!centerpointEditModeIsOn)}
                  data-testid="button.set_centerpoint"
                  disabled={isLoading || modalCalculateCstValueIsOpen || annotationEditModeIsOn}
                >
                  Correct Centerpoint
                </Button>
                <span data-tip="The 1mm diameter is outside of the bscan boundary!" data-for="button-calculate-cst" data-tip-disable={false} style={ buttonCalculateCstValueIsDisabled ? {cursor: 'not-allowed'} : {}}>
                  <Button
                    className="h-full rounded-md border border-transparent shadow-sm px-8 py-2 bg-green-600 text-base font-medium hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-0 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-green-50 active:bg-green-500 text-white"
                    disabled={buttonCalculateCstValueIsDisabled}
                    onClick={() => setModalCalculateCstValueIsOpen(true)}
                    data-testid="button.calculate_cst"
                    style={ buttonCalculateCstValueIsDisabled ? {pointerEvents: 'none'} : {} }
                  >
                    Finish and Calculate CST
                  </Button>
                </span>
                  {(!centerpointEditModeIsOn && !annotationEditModeIsOn && buttonCalculateCstValueIsDisabled) && <ReactTooltip id="button-calculate-cst" />}
              </div>
              )
            : (
              <div className="mt-2">
                <span data-tip="Submitted CST values cannot be changed" className="font-bold">
                  The CST was already calculated to be {selectedTask.cst.data} μm.
                </span>
                <ReactTooltip /*backgroundColor="#b2baff" textColor="#0015ba"*/ html={true} />
              </div>
            )
          }
          </div>
          <div className="pr-2 pt-2 flex justify-end">
          {currentBscanToShow?.annotations && (
              <div className="w-full flex flex-row">
                <span className="inline-flex mr-2">{currentSelectedBscan?.annotations?.length === 0 && `No `}Annotations</span>
                <AnnotationLayer
                  annotations={currentSelectedBscan?.annotations}
                  showAnnotationGroupsOnly={true}
                  onClick={toggleLayer}
                  layerNamesToHide={layerNamesToHide}
                  annotationEditModeIsOn={annotationEditModeIsOn}
                  annotationLayerNameToShow={currentAnnotationLayerToShow || firstAnnotationToShow}
                />
              </div>
          )}
          </div>
        </div>
      </div>
    )
  }


  return (
    <>
      <RouteLeavingGuard
        when={changedBscans.length > 0}
        shouldBlockNavigation={() => changedBscans.length > 0}
        title="There are changed annotations!"
        modalMessage={
          <>
            <h3>{`If you leave this page you’ll lose the edited annotations at ${changedBscans.length > 1 ? `the ${changedBscans.length} `:''}bscan${changedBscans.length > 1 ? 's':''} ${changedBscans.sort((a, b) => a - b).join(', ')}.`}</h3>
            <p className="mt-2 font-bold">Do you want to leave this page and discard the changes?</p>
          </>
        }
      />
      <div as="nav" className="w-full">
        <div className="mt-1 flex content-center justify-center flex-wrap">
          <ContentTop />
        </div>
        <div className="mt-6 mb-2 my-0 mx-auto" style={{width: bscan_width + newFundusWidth + 130}}>
          <ButtonActions />
        </div>
        <div className="flex my-0 mx-auto py-6 px-6 bg-white shadow-lg rounded-lg" style={{width: bscan_width + newFundusWidth + 150}}>

          <div
            className={classNames(
              'flex w-full hover:shadow-canvas',
            )}
            onMouseEnter={() => {
              mouseIsOverFundusAndBscanContainer.current = true
              document.body.style.overflow = 'hidden'
            }}
            onMouseLeave={() => {
              mouseIsOverFundusAndBscanContainer.current = false
              document.body.style.overflow = 'auto'
            }}
            onWheel={onWheel}
          >


            <div className="relative" style={{ height: newFundusHeight, width: newFundusWidth }}>
              {allImagesByScanId?.fundus?.presigned_url && (
                <>
                  <img
                    src={allImagesByScanId.fundus.presigned_url}
                    alt="fundus"
                    style={{height: bscan_height}}
                  />
                  <CanvasFundusAllBscanLines
                    height={fundus_height}
                    width={fundus_width}
                    lineWidth={1}
                    strokeStyle="rgba(255, 255, 255, 0.5)"
                    className="absolute inset-0 w-full h-auto"
                    bscans={allImagesByScanId.bscans}
                    changedBscans={changedBscans}
                  />
                  <CanvasFundusCenterpointLine
                    height={fundus_height}
                    width={fundus_width}
                    lineWidth={2}
                    strokeStyle="rgba(255, 170, 0, 0.75)" // "#fa0"
                    className="absolute inset-0 w-full h-auto"
                    bscanPositionOnFundus={{
                      xstart: centerpointBscanForFundus?.bscan_xstart,
                      xend: centerpointBscanForFundus?.bscan_xend,
                      y: fundus_height - centerpointBscanForFundus?.bscan_ystart,
                    }}
                    centerpointXposOnBscan={centerpointXpos}
                  />
                  <CanvasFundusCurrentBscan
                    height={fundus_height}
                    width={fundus_width}
                    lineWidth={2}
                    strokeStyle="rgba(255, 255, 0, 1)"
                    className="absolute inset-0 w-full h-auto"
                    currentSelectedBscanPosition={currentSelectedBscanPosition}
                  />
                  <EtdrsGrid
                    height={fundus_height}
                    width={fundus_width}
                    lineWidth={6}
                    className="absolute inset-0 w-full h-auto"
                    bscanPositionOnFundus={{
                      xstart: centerpointBscanForFundus?.bscan_xstart,
                      xend: centerpointBscanForFundus?.bscan_xend,
                      y: fundus_height - centerpointBscanForFundus?.bscan_ystart,
                    }}
                    centerpointXposOnBscan={centerpointXpos}
                    fundusPixelSpacing={fundusPixelSpacing}
                    config={{
                      oneMm: {
                        color: '#bf0',
                        lineWidth: 4,
                      },
                      threeMm: {
                        color: 'rgba(255, 255, 255, 0.75)', //'#fff',
                        lineWidth: 2,
                      },
                      sixMm: {
                        color: 'rgba(255, 255, 255, 0.75)', //'#fff',
                        lineWidth: 2,
                      },
                      centerpoint: {
                        color: '#fa0',
                        lineWidth: 2,
                      },
                      lines: {
                        color: 'rgba(200, 200, 200, 0.5)', //'#0fa',
                      },
                    }}
                  />
                </>
              )}
            </div>
            <div style={{ float: 'left', width: 100, height: 496-70, marginBottom: 0, marginLeft: 0 }}>
              <Slider
                height={496-70} // height - buttonsTop - buttonsBottom
                centerpointSlice={centerpointSlice}
                changedBscans={changedBscans}
              />
            </div>
            <div
              className={classNames(
                'relative',
                centerpointEditModeIsOn && 'centerpoint-edit-mode',
                annotationEditModeIsOn && 'annotation-edit-mode',
              )}
              style={{ height: bscan_height, width: bscan_width, overflow: 'auto' }}
            >
              {
                currentBscanToShow?.presigned_url && (
                  <>
                    <div
                      style={{ maxWidth: bscan_width, maxHeight: bscan_height}}
                      className="select-none"
                    >
                      <img
                        src={currentSelectedBscan?.presigned_url}
                        style={{ maxWidth: bscan_width, maxHeight: bscan_height, }}
                        alt={`bscan_${imageIndex}`}
                      />
                      <div className="absolute inset-0">
                        {currentBscanToShow?.annotations && (
                          <CanvasAnnotations
                            annotations={annotationsToShowFiltered}
                            height={bscan_height}
                            width={bscan_width}
                            lineWidth={2}
                          />
                        )}
                        <CanvasOneMmAroundCenterpoint
                          height={bscan_height}
                          width={bscan_width}
                          centerpoint={centerpoint}
                          // centerpointSlice={centerpointSlice}
                          centerpointSlice={centerpointSliceForBscan}
                          currentSlice={imageIndex}
                          bscanPixelSpacing={bscanPixelSpacing}
                          lineWidth={2}
                          strokeStyle="#bf0"
                          className="absolute inset-0"
                        />
                        {/* {imageIndex === centerpointSlice && */}
                        {imageIndex === centerpointSliceForBscan &&
                          <CanvasCenterpoint
                            height={bscan_height}
                            width={bscan_width}
                            centerpoint={centerpoint}
                            lineWidth={2}
                            strokeStyle="#fb0"
                            className="absolute inset-0"
                          />
                        }
                        {centerpointEditModeIsOn && (
                          <CanvasEditCenterpoint
                            height={bscan_height}
                            width={bscan_width}
                            lineWidth={2}
                            strokeStyle="#db2777"
                            strokeStyleClicked="#fd0"
                            className="absolute inset-0"
                            onSuccess={() => setCenterpointEditModeIsOn(false)}
                            currentSlice={imageIndex}
                            centerpoint={centerpoint}
                            bscanPixelSpacing={bscanPixelSpacing}
                          />
                        )}
                        {annotationEditModeIsOn && currentSelectedBscan?.annotations && (
                          <CanvasEditAnnotations
                            annotations={currentSelectedBscan?.annotations}
                            changedAnnotations={newAnnotations.current}
                            bscanNumber={bscanImageIndex}
                            annotationLayerNameToShow={currentAnnotationLayerToShow || firstAnnotationToShow}
                            height={bscan_height}
                            width={bscan_width}
                            lineWidth={2}
                            className="absolute inset-0"
                            callbackAnnotationHasChanged={(changedAnnotations) => {
                              const bscanNumber = bscanImageIndex
                              newAnnotations.current = {
                                ...newAnnotations.current,
                                [`bscan_${bscanNumber}`]: { ...changedAnnotations }
                              }
                              setAnnotationHasChanged(true)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )
              }
            </div>

          </div>

        </div>
      </div>
      {children}
      <ModalCalculateCstValue
        open={modalCalculateCstValueIsOpen}
        onCancel={() => setModalCalculateCstValueIsOpen(false)}
        onConfirmed={() => {
          // console.log('...confirmed!!!!')
          history.push('/')
          // setModalCalculateCstValueIsOpen(false)
        }}
        data={{
          vrc_ticket_id,
          retticket: 'LALA00011',
          taskId: selectedTask?.id,
          cst_temp_data: selectedTask?.cst_temp?.data,
          cst_data: selectedTask?.cst?.data,
        }}
        dispatch={dispatch}
      />
      <ModalSaveAnnotations
        open={modalSaveAnnotationsIsOpen}
        onCancel={() => setModalSaveAnnotationsIsOpen(false)}
        onSuccess={() => {
          console.log('...annotations saved!')
          setModalSaveAnnotationsIsOpen(false)
          setAnnotationHasChanged(false)
          setAnnotationEditModeIsOn(false)
          newAnnotations.current = {}
        }}
        data={{
          newAnnotations: newAnnotations.current,
          width: bscan_width,
          height: bscan_height,
          changedBscans,
        }}
        dispatch={dispatch}
      />
      <ModalWarnAboutChangedAnnotations
        open={modalWarnAboutChangedAnnotationsIsOpen}
        onCancel={() => {
          setModalWarnAboutChangedAnnotationsIsOpen(false)
        }}
        onSuccess={() => {
          // Clear all changed annotaions
          newAnnotations.current = {}
          setModalWarnAboutChangedAnnotationsIsOpen(false)
          setAnnotationHasChanged(false)
          setAnnotationEditModeIsOn(false)
        }}
        data={{
          newAnnotations: newAnnotations.current,
          width: bscan_width,
          height: bscan_height,
          changedBscans,
        }}
        dispatch={dispatch}
      />
    </>
  )
}

PageTask.propTypes = {
  children: PropTypes.any,
}

export default PageTask
