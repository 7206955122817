import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import store from '../../../store/store'
// import { timeout } from '../../../utils/utils'
// import { data } from './mockData' // for local dev with mockdata

export const fetchTaskByIdAsync = createAsyncThunk(
  'table/fetchTaskById',
  async (_taskId, { dispatch }) => {
    let data
    const taskId = Number(_taskId)
    const allData = store?.getState()?.table?.data
    if (!allData || (allData && allData.length === 0)) {
      // load data from server
      // const resultAction =
      await dispatch(fetchTasksAsync())
    }
    const tableData = store?.getState()?.table?.data
    if (tableData && tableData.length > 0) {
      data = tableData.filter(item => Number(item.id) === taskId)
      if (data.length === 1) {
        data = data[0]
      }
    }
    // console.log("------ table/fetchTaskById")
    // console.log(data)
    return data
  }
)

export const fetchScanByScanIdAsync = createAsyncThunk(
  'table/fetchScanByScanIdAsync',
  async (scanId) => {
    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    }
    // const dataToSend = {
    //   scan_id: scanId,
    // }
    // const paramsForGet = (new URLSearchParams(dataToSend)).toString()
    // const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/files?${paramsForGet}`
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/scans/${scanId}`
    // const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/scan/${scanId}`
    let data
    let response
    try {
      response = await fetch(url, {
        headers,
        method: 'GET',
        crossDomain: true,
      })
    } catch (e) {
      console.log(`### table/fetchScanByScanIdAsync error: ${scanId}`)
      console.log(e)
    }
    if (response?.ok === false) {
      data = {
        isError: true,
        status: response?.status,
      }
    } else {
      data = await response.json()
    }
    // const scanIdData = await response.json()
    // console.log("### table/fetchScanByScanIdAsync: FETCH data:")
    // console.log(data)
    return data
  }
)

export const fetchTasksAsync = createAsyncThunk(
  'table/fetchTasks',
  async () => {
    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    }
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/tasks`
    let data
    let response
    try {
      response = await fetch(url, {
        headers,
        // method: 'POST',
        method: 'GET',
        crossDomain: true,
        // body: JSON.stringify(datatToSend),
      })
      // console.log("#### fetchTasksAsync RESPONSE")
      // console.log(response)


      // TODO: Remove this later. Is for demo only...
      // await timeout(3000)

      if (response?.ok === false) {
        data = {
          isError: true,
          status: response?.status,
        }
      } else {
        data = await response.json()
      }
    } catch (error) {
      console.log("#### fetchTasksAsync ERROR FETCH!")
      console.log(error)
    }
    // console.log("data")
    return data
  }
)

export const fetchAllImagesByScanIdAsync = createAsyncThunk(
  'table/fetchAllImagesByScanIdAsync',
  async (scanId) => {
    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    }
    const dataToSend = {
      scan_id: scanId,
    }
    const paramsForGet = (new URLSearchParams(dataToSend)).toString()
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/files?${paramsForGet}`
    // const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/scans/${scanId}`
    // const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/scan/${scanId}`
    let data
    let response
    try {
      response = await fetch(url, {
        headers,
        method: 'GET',
        crossDomain: true,
      })
    } catch (e) {
      console.log(`### table/fetchScanByScanIdAsync error: ${scanId}`)
      console.log(e)
    }
    if (response?.ok === false) {
      data = {
        isError: true,
        status: response?.status,
      }
    } else {
      data = await response.json()
    }
    // console.log("### table/fetchScanByScanIdAsync: FETCH data:")
    // console.log(data)
    return data
  }
)

export const fetchCstValue = createAsyncThunk(
  'table/fetchCstValue',
  async () => {
    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    }
    const taskId = store?.getState()?.table?.selectedTask?.id
    if (!taskId) {
      return null
    }
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/tasks/${taskId}/cst`
    let data
    let response
    try {
      response = await fetch(url, {
        headers,
        method: 'GET',
        crossDomain: true,
      })
    } catch (e) {
      console.log(`### table/fetchCstValue error: ${taskId}`)
      console.log(e)
    }
    console.log("### table/fetchCstValue response.status")
    console.log(response.status)
    if (response?.ok === false) {
      data = {
        isError: true,
        status: response?.status,
      }
    } else {
      data = await response.json()
    }
    return data
  }
)

export const postCstValue = createAsyncThunk(
  'table/postCstValue',
  async () => {
    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    }
    const selectedTask = store?.getState()?.table?.selectedTask
    const cst_temp_data = selectedTask?.cst_temp?.data
    const taskId = selectedTask?.id
    if (!taskId || !cst_temp_data) {
      return null
    }
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/tasks/${taskId}/cst`
    /*
    POST /app/tasks/<taskid:int>/cst
    Body: {“cst”: {“data”: initial_cst}}
    */
    let data
    let response
    try {
      // for testing:
      // await timeout(2000)
      // response = {
      //   ok: true
      // }
      // live:
      response = await fetch(url, {
        headers,
        method: 'POST',
        crossDomain: true,
        body: JSON.stringify({
          cst: {
            data: cst_temp_data,
          }
        })
      })
    } catch (e) {
      console.log(`### table/postCstValue error: ${taskId}`)
      console.log(e)
    }
    if (response?.ok === false) {
      data = {
        isError: true,
        status: response?.status,
      }
    } else {
      data = await response.json()
    }
    return data
  }
)

export const postAnnotations = createAsyncThunk(
  'table/postAnnotations',
  async ({
    annotations,
    width,
    height,
  }) => {
    // annotations: bscan_x, x = bscanNumber
    /*
      {
        bscan_1: {ILM: {…}, BM: {…}},
        bscan_4: {ILM: {…}, BM: {…}}
      }
    */
    // validate annotations data and populate data to send
    const dataToSend = []
    Object.keys(annotations).forEach((annotation, i) => {
      Object.keys(annotations[annotation]).forEach(item => {
        // set array length to width, min to 0 and max to height
        annotations[annotation][item].data = annotations[annotation][item].data.slice(0, width).map(item => item < 0 ? 0 : item >= height ? height - 1 : item)
        dataToSend.push({
          file_id: annotations[annotation][item]?.file_id,
          biomarker_id: annotations[annotation][item]?.biomarker_id,
          type: 'LAYER',
          data: annotations[annotation][item]?.data,
        })
      })
    })

    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    }
    const selectedTask = store?.getState()?.table?.selectedTask
    const scanId = selectedTask?.scan_id
    if (!scanId) {
      return null
    }
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/annotation`

    /*
    POST /app/annotation
    [
      {
        "file_id": 20639,
        "biomarker_id": 13,
        "type": "LAYER",
        "data": [404, 405, 405, 405, ... ]
      }
    ]
    */

    let response
    let data
    try {
      // console.log('### tableSlice: table/postAnnotations')
      // console.log(headers)
      // console.log(url)
      // console.log('-------------------------------------')
      // console.log(JSON.stringify(dataToSend))
      // console.log('-------------------------------------')

      // // for testing:
      // await timeout(2000)
      // response = {
      //   ok: true, // test success
      //   // ok: false, // test error
      // }
      // // live:
      response = await fetch(url, {
        headers,
        method: 'POST',
        crossDomain: true,
        body: JSON.stringify(dataToSend),
      })
    } catch (e) {
      // console.log(`### table/postAnnotations error scanId: ${scanId}`)
      console.log(`### table/postAnnotations error:`)
      console.log(e)
    }
    if (response?.ok === false) {
      data = {
        isError: true,
        status: response?.status,
      }
    } else {
      data = await response.json()
    }
    return data
  }
)

export const postCenterpointValue = createAsyncThunk(
  'table/postCenterpointValue',
  async ({slice, xPos}) => {
    // console.log(`### tableSlice: xPos: ${xPos} slice: ${slice}`)
    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    }
    const selectedTask = store?.getState()?.table?.selectedTask
    const scanId = selectedTask?.scan_id
    if (!scanId) {
      return null
    }
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/annotation`
    const centerpointCurrent = getCenterpoint(true) // get original data only! (without VRC hack)
    const bscans = store?.getState()?.table?.allImagesByScanId?.bscans
    const newCenterpointBscan = bscans?.filter(bscan => bscan.bscan_number_vrc === slice)?.[0]
    const newCenterpoint = [...centerpointCurrent]
    newCenterpoint[0] = xPos
    // newCenterpoint[1] = slice
    newCenterpoint[1] = newCenterpointBscan.bscan_number
    let data
    let response
    try {
      // for testing:
      // await timeout(2000)
      // response = {
      //   ok: true
      // }
      // live:
      response = await fetch(url, {
        headers,
        method: 'POST',
        crossDomain: true,
        body: JSON.stringify(
          [
            {
              scan_id: scanId,
              type: 'CENTERPOINT',
              data: newCenterpoint
            }
          ]
        )
      })
    } catch (e) {
      console.log(`### table/postCenterpointValue error scanId: ${scanId}`)
      console.log(e)
    }
    if (response?.ok === false) {
      data = {
        isError: true,
        status: response?.status,
      }
    } else {
      data = await response.json()
    }
    return data
  }
)


export const getCenterpoint = (originalDataOnly = false) => {
  const scanByScanIdAnnotations = store?.getState()?.table?.scanByScanId?.annotations
  let centerpoint = null
  if (scanByScanIdAnnotations && scanByScanIdAnnotations.length > 0) {
    centerpoint = scanByScanIdAnnotations.filter(item => item.type === 'CENTERPOINT')?.[0]?.data
    // Milli: Yeah! VRC workaround.
    if (centerpoint && !originalDataOnly) {
      const bscans = store?.getState()?.table?.allImagesByScanId?.bscans
      const centerpointBscan = bscans?.filter(bscan => bscan.bscan_number_vrc === centerpoint?.[1])?.[0]
      if (centerpointBscan?.bscan_number) {
        const newCenterpoint = [...centerpoint]
        // add the bscan_number
        newCenterpoint.push(centerpointBscan?.bscan_number)
        centerpoint = newCenterpoint
      }
    }
  }
  return centerpoint
}

export const getCenterpointBscanForFundus = () => {
  const centerpoint = getCenterpoint()
  if (!centerpoint) {
    return null
  }
  const bscans = store?.getState()?.table?.allImagesByScanId?.bscans
  return bscans?.filter(bscan => bscan.bscan_number_vrc === centerpoint?.[1])?.[0]
}

export const getBscanPixelSpacing = () => {
  const scanByScanId = store?.getState()?.table?.scanByScanId
  return {
    x: scanByScanId?.bscan_pixel_spacing_x,
    y: scanByScanId?.bscan_pixel_spacing_y,
    spacingBetweenSlices: scanByScanId?.spacing_between_slices,
  }
}

export const getFundusPixelSpacing = () => {
  const scanByScanId = store?.getState()?.table?.scanByScanId
  return scanByScanId?.fundus_pixel_spacing_x
}

const changeAnnotationColor = annotation => {
  switch (annotation.biomarker?.id) {
    case 2: // 'ILM' -> 'Internal Limiting Membrane'
      annotation.biomarker.color = '#FF5959'
      break
    case 12: // 'RPE_Outer' -> 'Retinal Pigment Epithelium Outer'
    case 13: // 'BM' -> 'Burch's Membrane'
      annotation.biomarker.color = '#006bc9'
      break
    default:
  }
  return annotation
}

const changeAnnotationsColors = annotations => {
  // change color here by overwriting the color
  const newAnnotations = annotations.map(annotation => {
    return changeAnnotationColor(annotation)
  })
  return newAnnotations
}

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    pageIndex: 0, // current page
    pageSize: 5, //10, // items per page
    filter: null, // filter @ header/dropdown "Status"
    search: '', // search field
    data: [],
    selectedTask: null, // currently selected task (when the user clicks on a task in the table)
    status: 'idle',
    error: null,
    initiatedInitialLoad: false,
    scanByScanId: null, // current selected scan
    allImagesByScanId: null, // all images (bscan and fundus) by current scan
  },
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload
    },
    resetPageIndex: (state) => {
      state.pageIndex = 0
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload
    },
    resetPageSize: (state) => {
      state.pageSize = 10
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    resetFilter: (state, action) => {
      state.filter = ''
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    resetSearch: (state, action) => {
      state.search = ''
    },
    clearTableError: (state, action) => {
      state.error = null
    },
  },
  extraReducers: {
    [fetchTasksAsync.pending.type]: (state, action) => {
      state.status = 'loading'
      state.error = null
      state.initiatedInitialLoad = true
    },
    [fetchTasksAsync.fulfilled.type]: (state, action) => {
      state.status = 'ready'
      state.initiatedInitialLoad = true
      if (action?.payload?.isError && action?.payload?.isError === true) { // is set in loadTasks
        state.error = action.payload
      } else {
        state.error = null
        state.data = action.payload
      }
    },
    [fetchTasksAsync.rejected.type]: (state, action) => {
      state.status = 'rejected'
      state.initiatedInitialLoad = true
      state.error = null
    },
    ////// fetchTaskByIdAsync:
    [fetchTaskByIdAsync.fulfilled.type]: (state, action) => {
      state.status = 'ready'
      state.selectedTask = action?.payload
    },
    [fetchTaskByIdAsync.pending.type]: (state, action) => {
      state.status = 'loading'
      state.error = null
    },
    ////// fetchScanByScanIdAsync
    [fetchScanByScanIdAsync.fulfilled.type]: (state, action) => {
      state.status = 'ready'
      // console.log("--- fetchScanByScanIdAsync.fulfilled")
      // console.log(action.payload)
      state.scanByScanId = action.payload
    },
    [fetchScanByScanIdAsync.pending.type]: (state, action) => {
      state.status = 'loading'
      state.error = null
    },
    ////// fetchAllImagesByScanIdAsync
    [fetchAllImagesByScanIdAsync.fulfilled.type]: (state, action) => {
      state.status = 'ready'
      // console.log("--- fetchAllImagesByScanIdAsync.fulfilled")
      try {
        const bscans = action.payload.filter(item => item.logical_type === 'bscan')
        state.allImagesByScanId = {
          bscans: action.payload.filter(item => item.logical_type === 'bscan').map(layer => {
            // Milli: Yeah workaround. VRC is not using Dicom standard
            layer.bscan_number_vrc = bscans.length - 1 - layer.bscan_number
            if (layer.annotations.length > 0) {
              layer.annotations = changeAnnotationsColors(layer.annotations)
            }
            return layer
          }).sort((a, b) => a?.bscan_number - b?.bscan_number).reverse(),
          fundus: action.payload.filter(item => item.logical_type === 'fundus')[0],
        }
      } catch (e) {
        state.allImagesByScanId = null
      }
    },
    [fetchAllImagesByScanIdAsync.pending.type]: (state, action) => {
      state.status = 'loading'
      state.error = null
    },
    ////// fetchCstValue:
    [fetchCstValue.fulfilled.type]: (state, action) => {
      // console.log("### fetchCstValue.fulfilled")
      // console.log(action.payload)
      state.status = 'ready'
      state.selectedTask = { ...state.selectedTask, ...{ cst_temp: action.payload?.cst } }
    },
    [fetchCstValue.pending.type]: (state, action) => {
      // console.log("### fetchCstValue.pending")
      // console.log(action.payload)
      state.status = 'loading'
      state.error = null
    },
    [fetchCstValue.rejected.type]: (state, action) => {
      console.log("#### fetchCstValue REJECTED")
      console.log(action.payload)
    },
    ////// postCstValue:
    [postCstValue.fulfilled.type]: (state, action) => {
      // console.log("#### postCstValue FULLFILLED")
      // console.log(action.payload)
      state.status = 'ready'
      if (action?.payload?.isError === true) {
        /*
          in case of wrong cst value sent to BE
        */
        state.selectedTask.cst_temp.error = true
      } else {
        // save to state.selectedTask
        state.selectedTask = action.payload
        // state.selectedTask.cst_temp = { error: false }
        // save to elm in stae.data
        const id = action.payload.id
        state.data[state.data.findIndex(elm => elm.id === id)] = action.payload
      }
    },
    [postCstValue.pending.type]: (state, action) => {
      state.status = 'loading'
      state.selectedTask.cst_temp.error = false
    },
    [postCstValue.rejected.type]: (state, action) => {
      console.log("#### postCstValue REJECTED")
      console.log(action.payload)
      state.status = 'rejected'
      state.selectedTask.cst_temp.error = false
    },
    ////// postCenterpointValue:
    [postCenterpointValue.fulfilled.type]: (state, action) => {
      // console.log("#### postCenterpointValue FULLFILLED")
      // console.log(action.payload)
      state.status_postCenterpointValue = 'ready'
      if (action?.payload?.isError === true) {
        // state.selectedTask.......error = true
      } else {
        if (state.scanByScanId) {
          // Set new centerpoint on current scan
          state.scanByScanId.annotations = action.payload
          state.selectedTask = { ...state.selectedTask, ...{ cst_temp: null } }
        }
      }
    },
    [postCenterpointValue.pending.type]: (state, action) => {
      state.status_postCenterpointValue = 'loading'
      // state.selectedTask.......error = false
    },
    [postCenterpointValue.rejected.type]: (state, action) => {
      console.log("#### postCenterpointValue REJECTED")
      console.log(action)
      state.status_postCenterpointValue = 'rejected'
      // state.selectedTask.......error = false
    },
    ////// postAnnotations:
    [postAnnotations.fulfilled.type]: (state, action) => {
      // console.log("#### postAnnotations FULLFILLED")
      // console.log(action.payload)
      state.status_postAnnotations = 'ready'
      // if (action?.payload?.isError === true) {
      //   // state.selectedTask.......error = true
      // } else {
      //   if (state.scanByScanId) {
      //     // Set new centerpoint on current scan
      //     state.scanByScanId.annotations = action.payload
      //     state.selectedTask = { ...state.selectedTask, ...{ cst_temp: null } }
      //   }
      // }
      if (action?.payload?.isError === true) {
        // state.selectedTask.......error = true
      } else {
        if (state.scanByScanId) {
          // Set new annotations on current scan
          if (action?.payload?.length > 0) {
            // Now multiple annotations can be edited
            action.payload.forEach((annotation, i) => {
              const fileId = annotation.file_id
              const biomarkerId = annotation.biomarker_id
              // console.log(`-> fileId: ${fileId} biomarkerId: ${biomarkerId}`)
              if (fileId && biomarkerId) {
                const bscanWithFileId = {...state.allImagesByScanId.bscans.filter(bscan => bscan.id === fileId)}
                const annotationWithBiomarkerIdIndex = bscanWithFileId?.[0]?.annotations?.findIndex(annotation => annotation.biomarker_id === biomarkerId)
                // console.log(`index: ${annotationWithBiomarkerIdIndex}`)
                if (annotationWithBiomarkerIdIndex >= 0) {
                  bscanWithFileId[0].annotations[annotationWithBiomarkerIdIndex] = changeAnnotationColor(annotation)
                }
              }
            })
          }
        }
      }
    },
    [postAnnotations.pending.type]: (state, action) => {
      state.status_postAnnotations = 'loading'
      // state.selectedTask.......error = false
    },
    [postAnnotations.rejected.type]: (state, action) => {
      console.log("#### postAnnotations REJECTED")
      console.log(action)
      state.status_postAnnotations = 'rejected'
      // state.selectedTask.......error = false
    },


  },
})

// Action creators are generated for each case reducer function
export const {
  setPageIndex,
  resetPageIndex,
  setPageSize,
  resetPageSize,
  setFilter,
  resetFilter,
  setSearch,
  resetSearch,
  clearTableError,
} = tableSlice.actions

export default tableSlice.reducer
