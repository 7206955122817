import React from 'react'
import PropTypes from 'prop-types'
import { fn } from '../../utils/utils'

/*

  Edit annotations

*/
const CanvasEditAnnotations = ({
  width = 400,
  height = 400,
  lineWidth = 1,
  className,
  annotations,
  changedAnnotations,
  bscanNumber,
  children,
  annotationLayerNameToShow,
  callbackAnnotationHasChanged = fn,
}) => {
  const [ context, setContext ] = React.useState(null)
  // const context = React.useRef()
  const canvasRef = React.useRef(null)
  const mousePos = React.useRef({x: 0, y: 0})
  const mousePrevPos = React.useRef({x: 0, y: 0})
  // const mouseDown = React.useRef(null)
  const animationFrameId = React.useRef()

  const changedAnnotationsOfCurrentBscan = React.useRef(null)

  let frameCount = 0
  /* eslint-disable-next-line */
  const allLayersData = {}

  const allAnnotationData = React.useRef(allLayersData)
  const strokeStyle = '#0fa'

  React.useEffect(() => {
    const canvas = canvasRef?.current
    if (!context && canvas) {
      const renderCtx = canvas.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
    if (!context) {
      return
    }
    if (annotations) {
      annotations.forEach(annotation => {
        if (annotation.biomarker?.name) {
          allLayersData[annotation.biomarker?.name] = {...annotation}
        }
      })
    }

    const canvasPos = canvas?.getBoundingClientRect() // left = 946.5? .5? WTF? // sometimes getBoundingClientRect returns a float!

    const getPosition = (elm) => {
      let xPosition = 0
      let yPosition = 0

      while (elm) {
        xPosition += (elm.offsetLeft - elm.scrollLeft + elm.clientLeft)
        yPosition += (elm.offsetTop - elm.scrollTop + elm.clientTop)
        elm = elm.offsetParent
      }

      return {
        x: xPosition,
        y: yPosition
      }
    }

    const computeAllPointsBetweenTwoCoords = (x1, y1, x2, y2) => {
      let linePoints = []
      const distX = Math.abs(x2 - x1)
      const distY = Math.abs(y2 - y1)
      if (distX === 0) {
        return [{ x: x1, y: y1 }]
      }
      if (distX === 1) {
        return [{ x: x1, y: y1 }, { x: x2, y: y2 }]
      }
      const deltaYperX = distY / distX
      for (let i = 0; i <= distX; i++) {
        const deltaX = (y2 > y1) ? (i * deltaYperX) : (i * deltaYperX) * -1
        const newX = (x2 > x1) ? x1 + i : x1 - i
        const newY = y1 + deltaX
        linePoints.push({ x: newX, y: newY })
      }
      return linePoints
    }


    const bscanKey = `bscan_${bscanNumber}`
    // console.log(`EDIT: bscan: ${bscanNumber}`)
    const currentBscanWasChangedBefore = Object.prototype.hasOwnProperty.call(changedAnnotations, bscanKey)
    // console.log(`WAS CHANGED BEFORE: ${currentBscanWasChangedBefore}`)
    if (currentBscanWasChangedBefore) {
      changedAnnotationsOfCurrentBscan.current = changedAnnotations[bscanKey]
      Object.keys(changedAnnotations[bscanKey]).forEach(annotation => {
        allLayersData[annotation] = {...changedAnnotations[bscanKey][annotation]}
      })
    } else {
      changedAnnotationsOfCurrentBscan.current = null
      // set original annotations
      if (annotations) {
        annotations.forEach(annotation => {
          allLayersData[annotation.biomarker?.name] = {...annotation}
        })
      }
    }
    allAnnotationData.current = allLayersData

    const drawTheAnnotations = () => {
      let changedDataOfOtherLayer = []
      if (changedAnnotations?.[`bscan_${bscanNumber}`]) {
        const {[annotationLayerNameToShow]: remove, ...otherLayerObject} = changedAnnotations?.[`bscan_${bscanNumber}`]
        const otherLayerName = Object.values(otherLayerObject)[0]?.biomarker?.name
        changedDataOfOtherLayer = otherLayerObject[otherLayerName]?.data
      }

      //// draw the current selected annotation (ex. ILM or BM)
      const ctx = context
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      // draw the annotation
      ctx.beginPath()
      ctx.strokeStyle = strokeStyle
      allAnnotationData.current[annotationLayerNameToShow]?.data?.forEach((data, i) => {
        ctx.lineTo(i, data) // draw a line
      })
      ctx.lineWidth = lineWidth
      ctx.stroke()
      // draw the other changed annotation with a transparency
      if (changedDataOfOtherLayer.length > 0) {
        ctx.beginPath()
        ctx.strokeStyle = `rgba(0, 255, 170, 0.4)`
        changedDataOfOtherLayer.forEach((data, i) => {
          ctx.lineTo(i, data) // draw a line
        })
        ctx.lineWidth = lineWidth
        ctx.stroke()
      }
    }


    drawTheAnnotations()

    // get other annotation to be able to check if the drawn point is within this boundary
    // if the annotations for this bscan were changed before:
    // use the changed annotations else use original annotations
    const {[annotationLayerNameToShow]: currentLayerObject, ...otherLayer} = allAnnotationData.current
    const otherLayerObject = Object.values(otherLayer)[0]
    const pixelToBeAwayFromTheOtherLayer = 2

    const getCorrectedYPos = (currentCoord) => {
      let currentCoordY = 0
      const currentLayerIsBelow = (currentLayerObject.data[currentCoord.x] > otherLayerObject.data[currentCoord.x]) ? true : false

      if (!currentLayerIsBelow) {
        currentCoordY = (currentCoord.y >= otherLayerObject.data[currentCoord.x])
          ? otherLayerObject.data[currentCoord.x] - pixelToBeAwayFromTheOtherLayer
          : currentCoord.y
      } else {
        currentCoordY = (currentCoord.y <= otherLayerObject.data[currentCoord.x])
          ? otherLayerObject.data[currentCoord.x] + pixelToBeAwayFromTheOtherLayer
          : currentCoord.y
      }
      return currentCoordY
    }

    const draw = (event) => {
      if (!mousePrevPos.current) {
        return
      }
      const pos = getCursorPosition(event)
      if (!pos) {
        return
      }

      const newData = [...allAnnotationData.current[annotationLayerNameToShow].data]
      const diff = Math.abs(pos.x - mousePrevPos.current.x)

      if (diff <= 1) {
        // diff is 1 pixel between old and new position
        newData[pos.x] = getCorrectedYPos(pos)
      } else {
        // draw a line between old and new position
        const allPointsBetween = computeAllPointsBetweenTwoCoords(mousePrevPos.current.x, mousePrevPos.current.y, pos.x, pos.y)
        allPointsBetween.forEach((item, i) => {
          newData[item.x] = getCorrectedYPos(item)
        })
      }

      mousePrevPos.current = pos // set prevPos to new pos

      allAnnotationData.current[annotationLayerNameToShow].data = newData
      drawTheAnnotations()
    }

    const render = (event) => {
      frameCount++
      draw(event, frameCount)
      animationFrameId.current = window.requestAnimationFrame(() => render(event))
    }

    const reposition = (event) => {
      if (event) {
        mousePos.current.x = event.clientX - canvasPos.current.x
        mousePos.current.y = event.clientY - canvasPos.current.y
      }
    }

    const getCursorPosition = (e) => {
      if (!e) {
        return
      }
      return {
        x: e.clientX - Math.round(canvasPos.left), // sometimes getBoundingClientRect returns a float!
        y: e.clientY - Math.round(canvasPos.top) // sometimes getBoundingClientRect returns a float!
      }
    }

    const handleMousedown = (e) => {
      // mouseDown.current = true
      mousePrevPos.current = getCursorPosition(e)
      canvas.addEventListener('mousemove', handleMousemove, false)
      reposition(e)
    }

    const handleMouseup = (e) => {
      // mouseDown.current = false
      mousePrevPos.current = null
      canvas.removeEventListener('mousemove', handleMousemove, false)
      callbackAnnotationHasChanged(allAnnotationData.current)
    }

    const handleMousemove = (e) => {
      draw(e)
    }

    const handleMouseout = () => {
      canvas.removeEventListener('mousemove', handleMousemove, false)
      window.cancelAnimationFrame(animationFrameId.current)
    }

    // const handleMouseenter = (event) => {
    //   window.cancelAnimationFrame(animationFrameId.current)
    //   render(event)
    // }

    // const handleClick = () => {
    //   window.cancelAnimationFrame(animationFrameId.current)
    //   draw()
    // }

    if (canvas) {
      canvasPos.current = getPosition(canvas)
      canvas.addEventListener('mousedown', handleMousedown, false)
      canvas.addEventListener('mouseup', handleMouseup, false)
      // canvas.addEventListener('mousemove', handleMousemove, false)
      canvas.addEventListener('mouseout', handleMouseout, false)
      // canvas.addEventListener('mouseenter', handleMouseenter, false)
      // canvas.addEventListener('click', handleClick, false)
      render()

      return () => {
        if (canvas) {
          canvas.removeEventListener('mousedown', handleMousedown, false)
          canvas.removeEventListener('mouseup', handleMouseup, false)
          // canvas.removeEventListener('mousemove', handleMousemove, false)
          canvas.removeEventListener('mouseout', handleMouseout, false)
          // canvas.removeEventListener('mouseenter', handleMouseenter, false)
          // canvas.removeEventListener('click', handleClick, false)
        }
      }
    }


  }, [
    annotationLayerNameToShow,
    callbackAnnotationHasChanged,
    frameCount,
    context,
    allAnnotationData,
    annotations,
    allLayersData,
    changedAnnotations,
    bscanNumber,
    lineWidth,
  ])

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    ></canvas>
  )
}

CanvasEditAnnotations.propTypes = {
  annotations: PropTypes.array,
  changedAnnotations: PropTypes.object,
  bscanNumber: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  annotationLayerNameToShow: PropTypes.string,
  callbackAnnotationHasChanged: PropTypes.func,
}

export default CanvasEditAnnotations
