import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RefreshIcon } from '@heroicons/react/outline'
import Login from '../components/auth/Login'
import Footer from '../components/footer/Footer'
import { LayoutFull } from '../layouts'
import { Auth } from 'aws-amplify'
import { setUser, setToken } from '../components/auth/store/userSlice'

const PrivateRoute = ({ children, path, exact, }) => {
  // TODO: (change token in 'isSignedIn'?)
  const token = useSelector((state) => state.user.token)
  const dispatch = useDispatch()
  const [isInitiated, setIsInitiated] = React.useState(false)

  React.useEffect(() => {
    if (!token) {
      const getToken = async () => {
        const user = await Auth.currentAuthenticatedUser() || {}
        if (user) {
          const token = user?.signInUserSession?.idToken?.jwtToken
          dispatch(setToken(token))

            try {
              const headers = {
                'Content-Type': 'application/json',
                'Authorization': token,
              }
              const url = `${process.env.REACT_APP_BACKEND_API_URL}/app/user`
              let response
              try {
                response = await fetch(url, {
                  headers,
                  method: 'GET',
                  crossDomain: true,
                })
              } catch (e) {
                console.log("#### ERROR FETCH!")
                console.log(e)
              }

              const userData = await response.json()
              if (userData) {
                const setUserData = {
                  email: userData.email,
                  first_name: userData.first_name,
                  last_name: userData.last_name,
                  site: userData.site,
                  studies: userData.studies,
                }

                dispatch(setUser(setUserData))
              } else {
                console.log('Invalid user object')
              }
            } catch (e) {
              console.log("#### ERROR FETCH!")
              console.log(e)
            }
        } else {
          console.log('We don\'t have user!')
        }
      }
      getToken()
      setIsInitiated(true)
    }
  }, [dispatch, isInitiated, token])

  return (
    !isInitiated
      ? (
          <LayoutFull>
            <>
              <div className="min-h-screen flex items-center justify-center">
                <RefreshIcon className="self-center text-gray-300 flip-horizontal w-16 h-16 bg-transparent rounded-md spin-back" />
              </div>
              <div className="fixed w-full h-16">
                <Footer />
              </div>
            </>
          </LayoutFull>
        )
      : (
        token
          ? (
            <Route path={path} exact={exact}>{children}</Route>
          )
          : (
            <LayoutFull>
              <Login />
            </LayoutFull>
          )
        )
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string,
  exact: PropTypes.bool,
  isInitiated: PropTypes.bool,
}

export default PrivateRoute
