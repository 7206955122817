import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Amplify from 'aws-amplify'
import store from './store/store'

import './styles/index.css'
import './styles/index.scss'
import App from './App'

// TODO: Hmm... doesn't work as expected. See "./__mocks__/handlers.js"
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./__mocks__/browser')
//   worker.start()
// }

const envKeys = [
  'REACT_APP_AWS_APIGATEWAY_REGION',
  'REACT_APP_AWS_APIGATEWAY_URL',
  'REACT_APP_AWS_COGNITO_REGION',
  'REACT_APP_AWS_COGNITO_USER_POOL_ID',
  'REACT_APP_AWS_COGNITO_APP_CLIENT_ID',
  'REACT_APP_BACKEND_API_URL',
]

const envNotSet = envKeys.filter(value => process.env[value] === undefined)

if (envNotSet.length > 0) {
  console.log('----------------------------------------------')
  envNotSet.forEach((item, i) => {
    console.log(`> ERROR: Set env ${item}`)
  })
  console.log('----------------------------------------------')
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'frontend-webapp',
        endpoint: process.env.REACT_APP_AWS_APIGATEWAY_URL,
        region: process.env.REACT_APP_AWS_APIGATEWAY_REGION,
      },
    ]
  }
})

/*

Mock stuff: Doesn’t work right now:

*/
// async function render() {
//   if (process.env.REACT_APP_MOCKED_DATA === "true") {
//   // if (process.env.REACT_APP_MOCKED_DATA === "true") {
//     // if (window.location.pathname === '/abc') {
//     //   window.location.pathname = '/abc/'
//     //   return
//     // }
//
//     // eslint-disable-next-line
//     const { worker } = require('./__mocks__/browser')
//
//     await worker.start({
//       waitUntilReady: true,
//       // serviceWorker: {
//       //   url: `${process.env.PUBLIC_URL}/mockServiceWorker.js`,
//       // },
//       serviceWorker: {
//         url: `/mockServiceWorker.js`,
//       },
//       onUnhandledRequest: (req) => {
//         console.error('Found an unhandled %s request to %s', req.method, req.url.href)
//       },
//     })
//     console.log('---- MOCKSERVER started.')
//   }
//
//   ReactDOM.render(
//     <Provider store={store}>
//       <App />
//     </Provider>,
//     document.getElementById('root')
//   )
//
// }
//
// render()

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
