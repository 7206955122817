import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {
  BellIcon,
  MenuIcon,
  XIcon,
  UserIcon,
  GlobeIcon,
  HomeIcon,
  // CalendarIcon,
  LogoutIcon,
  // AdjustmentsIcon,
} from '@heroicons/react/outline'
import { resetToken } from '../auth/store/userSlice'
import RetinSightLogo from '../../assets/svg/ris_logo_color_negative.svg'

import ModalExample from '../modals/example/ModalExample'

const classNames = (...classes) => classes.filter(Boolean).join(' ')

// const Header = ({
//   userdata: {
//     first_name,
//     last_name,
//   },
// }) => {
const Header = ({
  userData: {
    first_name = null,
    last_name = null
  } = null,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [isModalExampleOpen, setModalExampleOpen] = React.useState(false)

  const navigation = [
    { name: 'Overview', linkTo: '/', icon: HomeIcon },
  ]
  const profile = [
    { name: 'Sign out', icon: LogoutIcon, testId:"menuLogout", onClick: () => {
        dispatch(resetToken())
      }
    },
  ]

  if (first_name && last_name) {
    // add the first and lastname of the user to the array at 1st position
    profile.unshift(
      { name: `${first_name} ${last_name}`, icon: UserIcon, testId:"menuUser", },
    )
  }

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800 w-full">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      src={RetinSightLogo}
                      className="h-14 w-30"
                      alt="RetinSight • Clinical Trial Support"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item, itemIdx) => {
                        const isCurrentLocation = location.pathname === item.linkTo
                        return (
                          <Link
                            key={item.name}
                            to={item.linkTo}
                            className={classNames(
                              isCurrentLocation
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                              ,
                              'group cursor-pointer flex items-center px-2 py-2 text-base font-normal rounded-md'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                isCurrentLocation
                                  ? 'text-gray-300'
                                  : 'text-gray-400 group-hover:text-gray-300'
                                ,
                                'mr-1 flex-shrink-0 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        )}
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      {({ open }) => (
                        <>
                          <div className="flex items-center">
                            <Menu.Button
                              className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                              data-testid="buttonMenuUser"
                            >
                              <span className="sr-only">Open user menu</span>
                              <UserIcon className="h-6 w-6" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {profile.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <button
                                      // href="#"
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'w-full block px-4 py-2 text-sm text-gray-700 flex items-center'
                                      )}
                                      onClick={item.onClick}
                                      data-testid={item.testId || ""}
                                    >
                                      <item.icon
                                        className={classNames(
                                          'text-gray-400 group-hover:text-gray-300',
                                          'mr-1 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span className="pl-2 whitespace-nowrap">{item.name}</span>
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item, itemIdx) =>
                  itemIdx === 0 ? (
                    <Fragment key={item}>
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <a href="#" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
                        {item}
                      </a>
                    </Fragment>
                  ) : (
                    <a
                      key={item}
                      href="#"
                      className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    >
                      {item}
                    </a>
                  )
                )}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <UserIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">Hans Mustermann</div>
                    <div className="text-sm font-medium leading-none text-gray-400">hans@example.com</div>
                  </div>
                  <button className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Change language</span>
                    <GlobeIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <button
                    className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    onClick={() => setModalExampleOpen(true)}
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      onClick={() => setModalExampleOpen(true)}
                    />
                  </button>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  {profile.map((item) => (
                    <a
                      key={item}
                      href="#"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      {item}
                    </a>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <ModalExample
        open={isModalExampleOpen}
        onClose={setModalExampleOpen}
      />
    </>
  )
}

Header.propTypes = {
  userData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
}

export default Header
