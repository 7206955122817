import React from 'react'
import PropTypes from 'prop-types'
// import { classNames } from '../../utils/utils'

const EtdrsGrid = ({
  width = 400,
  height = 400,
  className,
  bscanPositionOnFundus,
  centerpointXposOnBscan,
  fundusPixelSpacing,
  children,
  config={
    oneMm: {
      strokeColor: '#bf0',
      lineWidth: 2,
    },
    threeMm: {
      strokeColor: '#0ff',
      lineWidth: 2,
    },
    sixMm: {
      strokeColor: '#a0f',
      lineWidth: 2,
    },
    centerpoint: {
      strokeColor: '#fa0',
      lineWidth: 2,
    },
  },
}) => {
  const canvasRef = React.useRef(null)
  const [context, setContext] = React.useState(null)

  const { xstart = 0, xend = 0, y = 0 } = bscanPositionOnFundus || {}
  const oneMmInPixelDiameter = (1 / fundusPixelSpacing) / 2
  // console.log(`### oneMmInPixelDiameter: ${oneMmInPixelDiameter}`)

  React.useEffect(() => {
    const canvas = canvasRef.current
    if (!context && canvas) {
      const renderCtx = canvas.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
    if (context) {
      const centerpoint = {
        x: xstart + centerpointXposOnBscan,
        y,
      }

      // draw line of the bscan where the centerpoint is placed
      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      // context.save()
      // context.strokeStyle = strokeStyle
      // context.lineWidth = lineWidth
      // context.fillStyle = '#ff0'
      // context.moveTo(xstart, y)
      // context.arc(75, 75, 50, 0, Math.PI * 2, true) // Outer circle
      // context.arc(xstart, y, 150, 0, Math.PI * 2, true) // Outer circle

      // 1 mm diameter
      context.beginPath()
      context.setLineDash([20, 20])
      context.strokeStyle = config?.oneMm?.color
      context.lineWidth = config?.oneMm?.lineWidth
      context.arc(centerpoint.x, centerpoint.y, oneMmInPixelDiameter, 0, Math.PI * 2, true)
      context.stroke()
      // 3 mm diameter
      context.beginPath()
      context.setLineDash([])
      context.strokeStyle = config?.threeMm?.color
      context.lineWidth = config?.threeMm?.lineWidth
      context.arc(centerpoint.x, centerpoint.y, oneMmInPixelDiameter * 3, 0, Math.PI * 2, true)
      context.stroke()
      // 6 mm diameter
      context.beginPath()
      context.setLineDash([])
      context.strokeStyle = config?.sixMm?.color
      context.lineWidth = config?.sixMm?.lineWidth
      context.arc(centerpoint.x, centerpoint.y, oneMmInPixelDiameter * 6, 0, Math.PI * 2, true)
      context.stroke()

      // lines rotated
      context.beginPath()
      context.setLineDash([])
      // Rotated rectangle
      context.translate(centerpoint.x, centerpoint.y) // translate to center of shape
      context.rotate(45 * Math.PI / 180)
      context.translate(0, 0) // translate center back to 0,0

      //// 45° rotated lines
      const centerX = 0
      const centerY = 0
      const width = oneMmInPixelDiameter * 12
      const height = 4
      context.fillStyle = config?.lines?.color
      context.fillRect(-(oneMmInPixelDiameter * 6), centerY, width, height)
      // move the origin to the canvas' center
      context.translate(centerX, centerY)
      // add 45 degrees rotation
      context.rotate(90 * Math.PI / 180)
      // 2nd
      context.fillStyle = config?.lines?.color
      context.fillRect(0 - (oneMmInPixelDiameter * 6), centerY, width, height)
      // move the origin to the canvas' center
      context.translate(centerX, centerY)
      // add 45 degrees rotation
      context.rotate(45 * Math.PI / 180)
      context.setTransform(1, 0, 0, 1, 0, 0)

      // clip inside (mask)
      context.save()
      context.globalCompositeOperation = 'destination-out'
      context.beginPath()
      context.arc(centerpoint.x, centerpoint.y, oneMmInPixelDiameter, 0, 2 * Math.PI, false)
      context.fillStyle = '#000'
      context.fill()
      context.restore()

      // draw center
      context.beginPath()
      context.strokeStyle = config?.centerpoint?.color
      context.arc(centerpoint.x, centerpoint.y, 2, 0, Math.PI * 2, true)
      context.stroke()
    }
  }, [context, xstart, xend, y, config, oneMmInPixelDiameter, centerpointXposOnBscan, ])

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    ></canvas>
  )
}
EtdrsGrid.propTypes = {
  bscanPositionOnFundus: PropTypes.shape({
    xstart: PropTypes.number,
    xend: PropTypes.number,
    y: PropTypes.number,
  }),
  centerpointXposOnBscan: PropTypes.number,
  fundusPixelSpacing: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  config: PropTypes.shape({
    oneMm: PropTypes.shape({
      color: PropTypes.string,
      lineWidth: PropTypes.number,
    }),
    threeMm: PropTypes.shape({
      color: PropTypes.string,
      lineWidth: PropTypes.number,
    }),
    sixMm: PropTypes.shape({
      color: PropTypes.string,
      lineWidth: PropTypes.number,
    }),
    centerpoint: PropTypes.shape({
      color: PropTypes.string,
      lineWidth: PropTypes.number,
    }),
    lines: PropTypes.shape({
      color: PropTypes.string,
    }),
  }),
}

export default EtdrsGrid
