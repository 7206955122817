import React from 'react'
import PropTypes from 'prop-types'
import { Prompt, useHistory } from 'react-router-dom'
import RouteLeavingGuardModal from './RouteLeavingGuardModal'

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  title,
  modalMessage,
}) => {
  const [ modalVisible, setModalVisible ] = React.useState(false)
  const [ lastLocation, setLastLocation ] = React.useState(null)
  const [ confirmedNavigation, setConfirmedNavigation ] = React.useState(false)

  const history = useHistory()

  const closeModal = () => {
    setModalVisible(false)
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      history.push(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, history, ])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <RouteLeavingGuardModal
        open={modalVisible}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
        title={title}
        modalMessage={modalMessage}
      />
    </>
  )
}

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool,
  navigate: PropTypes.func,
  shouldBlockNavigation: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  modalMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default RouteLeavingGuard
