import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import InfoBox from '../../components/infobox'
import ModalError from '../../components/modals/error/ModalError'
import Table from '../../components/table'
import { columns } from '../../components/table/store/mockData'
import { fetchTasksAsync, clearTableError } from '../../components/table/store/tableSlice'
import { resetToken } from '../../components/auth/store/userSlice'

const PageDashboard = ({
  children = null,
}) => {
  const dispatch = useDispatch()
  const { site = {}, studies = [] } = useSelector((state) => state?.user?.data || {})
  const { data = [], error, initiatedInitialLoad } = useSelector((state) => state?.table || {})

  React.useEffect(() => {
    if (!initiatedInitialLoad) {
      // load initial data from server
      dispatch(fetchTasksAsync())
    }
  })

  const siteName = site?.name_short
  const studyName = {
    short: studies?.[0]?.studyname_short,
    long: studies?.[0]?.studyname_long,
  }
  const sponsorName = studies?.[0]?.sponsor?.name

  const fetchTasks = React.useCallback(async () => {
    dispatch(fetchTasksAsync())
  }, [dispatch])


  return (
    <>
    <div as="nav" className="w-full">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="mt-4 mx-auto bg-white w-full flex items-center justify-between">
          <div className="h-18 w-full flex flex-row-reverse">
            <div className="p-2">
              {siteName && (
                <InfoBox
                  title="Study Site: "
                  content={siteName}
                />
              )}
              {studyName.long ? (
                <InfoBox
                  title="Study: "
                  content={studyName.long}
                />
              ) : (
                <InfoBox
                  title="Study: "
                  content="Not assigned to any study"
                />
              )}
              {sponsorName ? (
                <InfoBox
                  title="Sponsor: "
                  content={sponsorName}
                />
              ) : (
                <InfoBox
                  title="Sponsor: "
                  content="No Sponsor"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-6 mb-6">
        <Table
          columns={columns}
          data={data}
          onReloadData={fetchTasks}
        />
        <ModalError
          open={error && error.isError ? true : false}
          onClose={() => {
            dispatch(clearTableError())
            dispatch(resetToken())
          }}
          title="Authentication error"
          content="There was an error. You need to sign in."
        />
      </div>
      {children}
    </>
  )
}

PageDashboard.propTypes = {
  children: PropTypes.any,
}

export default PageDashboard
